import React from 'react'
import Dock from 'react-dock'
import { HeadImage } from '../headimage'
import { WpTextContent } from '../wptextcontent'
import renderHTML from 'react-render-html'
import Img from 'react-cool-img'
import loadingImage from '../images/loading.gif'
import './styles.scss'

export class InfoDock extends React.Component<Props, State> {
  constructor(props) {
    super(props)
    this.state = {
      headImageIndex: 0,
      isMobile: window.innerWidth < 600,
      isTablet: window.innerWidth < 800,
    }
    this.dockContentRef = React.createRef()
  }

  componentDidUpdate(prevProps) {
    const { activeObject } = this.props
    const { activeObject: prevActiveObject } = prevProps
    if (activeObject !== prevActiveObject) {
      // show first image of new object
      this.setState({ headImageIndex: 0 })
      // reset the scroll position of the docks content
      this.dockContentRef.current.scrollIntoView()
    }
  }

  render() {
    return (
      <div className={this.state.isMobile ? 'info-dock mobile' : 'info-dock'}>
        <Dock
          position={this.state.isMobile ? 'bottom' : 'left'}
          size={this.state.isMobile ? 0.65 : this.state.isTablet ? 0.5 : 0.3}
          dimMode='none'
          isVisible={this.props.dockIsVisible}
        >
          <span ref={this.dockContentRef} />{' '}
          {/* this is just a placehoder element at the top of the content that can be scrolled into view for resetting the scroll top */}
          {
            /* you can pass a function as a child here */
            this.props.activeObject.length
              ? console.log('NOT ACTIVE')
              : console.log('ACTIVE', this.props.activeObject)
          }
          <div className='dock'>
            {this.state.isMobile && <div className='top-strip' />}
            <i
              className='icon close small'
              onClick={() => this.props.closeInfoDock()}
            />
            <div className='content-wrapper'>
              {this.props.activeObject.type === 'grafik' ||
              this.props.activeObject.type === 'brandstaette'
                ? //////////////////////////////////////////
                  // DISPLAY IMAGE FOR GRAFIK / GEMÄLDE
                  //////////////////////////////////////////
                  // console.log('ACF grafik_bild:', this.props.activeObject.acf.grafik_bild),
                  !this.state.isMobile &&
                  this.props.activeObject.acf &&
                  // DESKTOP: head-image BEFORE title
                  this.props.activeObject.acf.grafik_bild !== undefined &&
                  this.props.activeObject.acf.grafik_bild !== false && (
                    <HeadImage
                      imgSource={
                        this.props.activeObject.acf.grafik_bild.sizes
                          .medium_large
                      }
                      lightboxURL={this.props.activeObject.acf.grafik_bild.url}
                      lightboxDescription={
                        this.props.activeObject.acf.grafik_bild.description
                      }
                      openLightbox={this.props.openLightbox}
                    />
                  )
                : //////////////////////////////////
                  // // DISPLAY IMAGE FOR OBJEKT
                  /////////////////////////////////////

                  // DESKTOP: head-image & thumbs BEFORE title
                  !this.state.isMobile &&
                  this.props.activeObject.acf &&
                  this.props.activeObject.acf.objekt_bilder !== undefined &&
                  this.props.activeObject.acf.objekt_bilder !== false && (
                    <React.Fragment>
                      <HeadImage
                        imgArray={this.props.activeObject.acf.objekt_bilder}
                        headImageIndex={this.state.headImageIndex}
                        openLightbox={this.props.openLightbox}
                      />
                      <div className='caption'>
                        <WpTextContent
                          content={
                            this.props.activeObject.acf.objekt_bilder[
                              this.state.headImageIndex
                            ].objekt_bild.description
                          }
                          showOnMap={this.props.showOnMap}
                          locateObject={this.props.locateObject}
                          getObjectWPInformationByName={
                            this.props.getObjectWPInformationByName
                          }
                          getObjectFeaturesByName={
                            this.props.getObjectFeaturesByName
                          }
                          getObjectCoordinates={this.props.getObjectCoordinates}
                          displayObjectInfo={this.props.displayObjectInfo}
                          flyToLocation={this.props.flyToLocation}
                          toggleCarree={this.props.toggleCarree}
                        />
                      </div>
                      {this.props.activeObject.acf.objekt_bilder.length > 1 && (
                        <div className='head-thumbs'>
                          {this.props.activeObject.acf.objekt_bilder.map(
                            (bild, index) => (
                              <React.Fragment>
                                <Img
                                  placeholder={loadingImage}
                                  src={bild.objekt_bild.sizes.medium}
                                  onClick={() =>
                                    this.setState({ headImageIndex: index })
                                  }
                                  className={
                                    index === this.state.headImageIndex
                                      ? 'head-thumb active'
                                      : 'head-thumb'
                                  }
                                />
                              </React.Fragment>
                            )
                          )}
                        </div>
                      )}
                    </React.Fragment>
                  )}

              <div className='description-content'>
                <h3>
                  {this.props.activeObject.title
                    ? renderHTML(this.props.activeObject.title.rendered)
                    : 'Titel fehlt'}
                </h3>
                {this.props.activeObject.acf && (
                  <div className='dates'>
                    {this.props.activeObject.acf.eckdaten}
                  </div>
                )}
                {this.state.isMobile &&
                  this.props.activeObject.acf &&
                  // MOBILE: garfik/brandstätte AFTER title
                  this.props.activeObject.acf.grafik_bild !== undefined &&
                  this.props.activeObject.acf.grafik_bild !== false && (
                    <HeadImage
                      imgSource={
                        this.props.activeObject.acf.grafik_bild.sizes
                          .medium_large
                      }
                      lightboxURL={this.props.activeObject.acf.grafik_bild.url}
                      lightboxDescription={
                        this.props.activeObject.acf.grafik_bild.description
                      }
                      openLightbox={this.props.openLightbox}
                    />
                  )}
                {
                  // MOBILE: head-image & thumbs AFTER title
                  this.state.isMobile &&
                    this.props.activeObject.acf &&
                    this.props.activeObject.acf.objekt_bilder !== undefined &&
                    this.props.activeObject.acf.objekt_bilder !== false && (
                      <React.Fragment>
                        <HeadImage
                          imgArray={this.props.activeObject.acf.objekt_bilder}
                          headImageIndex={this.state.headImageIndex}
                          openLightbox={this.props.openLightbox}
                        />
                        <div className='caption'>
                          <WpTextContent
                            content={
                              this.props.activeObject.acf.objekt_bilder[
                                this.state.headImageIndex
                              ].objekt_bild.description
                            }
                            showOnMap={this.props.showOnMap}
                            locateObject={this.props.locateObject}
                            getObjectWPInformationByName={
                              this.props.getObjectWPInformationByName
                            }
                            getObjectFeaturesByName={
                              this.props.getObjectFeaturesByName
                            }
                            getObjectCoordinates={
                              this.props.getObjectCoordinates
                            }
                            displayObjectInfo={this.props.displayObjectInfo}
                            flyToLocation={this.props.flyToLocation}
                            toggleCarree={this.props.toggleCarree}
                          />
                        </div>
                        {this.props.activeObject.acf.objekt_bilder.length >
                          1 && (
                          <div className='head-thumbs'>
                            {this.props.activeObject.acf.objekt_bilder.map(
                              (bild, index) => (
                                <React.Fragment>
                                  <Img
                                    placeholder={loadingImage}
                                    src={bild.objekt_bild.sizes.medium}
                                    onClick={() =>
                                      this.setState({ headImageIndex: index })
                                    }
                                    className={
                                      index === this.state.headImageIndex
                                        ? 'head-thumb active'
                                        : 'head-thumb'
                                    }
                                  />
                                </React.Fragment>
                              )
                            )}
                          </div>
                        )}
                      </React.Fragment>
                    )
                }
                <WpTextContent
                  content={
                    this.props.activeObject.type === 'grafik' ||
                    this.props.activeObject.type === 'brandstaette'
                      ? this.props.activeObject.acf.grafik_bild !== undefined &&
                        this.props.activeObject.acf.grafik_bild !== false
                        ? this.props.activeObject.acf.grafik_bild.description
                          ? '<p>' +
                            this.props.activeObject.acf.grafik_bild
                              .description +
                            '</p>'
                          : 'Bildlegende fehlt...'
                        : 'Bild fehlt...'
                      : this.props.activeObject.content
                      ? this.props.activeObject.content.rendered
                      : 'Beschreibung fehlt...'
                  }
                  showOnMap={this.props.showOnMap}
                  locateObject={this.props.locateObject}
                  getObjectWPInformationByName={
                    this.props.getObjectWPInformationByName
                  }
                  getObjectFeaturesByName={this.props.getObjectFeaturesByName}
                  getObjectCoordinates={this.props.getObjectCoordinates}
                  displayObjectInfo={this.props.displayObjectInfo}
                  flyToLocation={this.props.flyToLocation}
                  toggleCarree={this.props.toggleCarree}
                />
                {/* this.props.activeObject.content
                  ? renderHTML(this.props.activeObject.content.rendered)
                : 'Description Text missing...' */}
              </div>
            </div>
          </div>
        </Dock>
      </div>
    )
  }
}
