import React from 'react'
import { Dropdown } from 'semantic-ui-react'
import Masonry from 'react-masonry-css'
import './styles.scss'

const breakpointColumnsObj = {
  default: 5,
  1800: 4,
  1200: 3,
  700: 2,
}

export class ImageResults extends React.Component<Props, State> {
  constructor(props) {
    super(props)
    this.isMobile = window.innerWidth < 600
    this.state = {
      filteredImages: [],
      entstehungFilter: null,
      entstehungValue: null,
      motivFilter: null,
      motivValue: null,
      artFilter: null,
      artValue: null,
      urheberFilter: null,
      urheberValue: null,
      motivTerms: [{ key: '-1', text: 'Not Set', value: '-1' }],
      urheberTerms: [{ key: '-1', text: 'Not Set', value: '-1' }],
      artTerms: [{ key: '-1', text: 'Not Set', value: '-1' }],
      entstehungTerms: [
        { key: '1500–1700', text: '1500–1700', value: '1500–1700' },
        { key: '1700–1800', text: '1700–1800', value: '1700–1800' },
        { key: '1800–1830', text: '1800–1830', value: '1800–1830' },
        { key: '1830–1860', text: '1830–1860', value: '1830–1860' },
        { key: '1861', text: '1861', value: '1861' },
        { key: '1862–1900', text: '1862–1900', value: '1862–1900' },
        { key: '1900–1950', text: '1900–1950', value: '1900–1950' },
        { key: 'nach 1950', text: 'nach 1950', value: 'nach 1950' },
      ],
    }
    this.locateOnMap = this.locateOnMap.bind(this)
    this.resetAllFilters = this.resetAllFilters.bind(this)
  }

  getAllTaxonomyTerms(taxonomyName, filterName) {
    let dataRoute = 'https://altglarus.ch/backend/wp-json/wp/v2/' + taxonomyName

    fetch(dataRoute)
      .then((response) => {
        if (response.ok) {
          return response.json()
        } else {
          throw new Error("Server response wasn't OK")
        }
      })
      .then((responseData) => {
        console.log('TAXONOMY TERMS FOR ', taxonomyName, ' :::::', responseData)
        let filterContent = responseData.map(function (currObj) {
          return {
            key: currObj.id.toString(),
            text: currObj.name,
            value: currObj.id.toString(),
          }
        })
        console.log('filterContent: ', filterContent)
        this.setState(
          {
            [filterName]: filterContent,
          },
          () => {
            console.log(' FILTER TERMS::', this.state[filterName])
          }
        )
      })
  }

  onEntstehungChange = (e, data) => {
    console.log('onEntstehungChange: ', data.value)
    if (data.value) {
      //set the filter
      this.setState({ entstehungFilter: data.value.toString() })
      this.setState({ entstehungValue: data.value })
    } else {
      //no filter
      this.setState({ entstehungFilter: null })
      this.setState({ entstehungValue: null })
    }
  }
  onMotivChange = (e, data) => {
    console.log('onMotivChange: ', data.value)
    if (data.value) {
      //set the filter
      this.setState({ motivFilter: data.value.toString() })
      this.setState({ motivValue: data.value })
    } else {
      //no filter
      this.setState({ motivFilter: null })
      this.setState({ motivValue: null })
    }
  }
  onArtChange = (e, data) => {
    console.log('onArtChange: ', data.value)
    if (data.value) {
      //set the filter
      this.setState({ artFilter: data.value.toString() })
      this.setState({ artValue: data.value })
    } else {
      //no filter
      this.setState({ artFilter: null })
      this.setState({ artValue: null })
    }
  }
  onUrheberChange = (e, data) => {
    console.log('onUrheberChange: ', data.value)
    if (data.value) {
      //set the filter
      this.setState({ urheberFilter: data.value.toString() })
      this.setState({ urheberValue: data.value })
    } else {
      //no filter
      this.setState({ urheberFilter: null })
      this.setState({ urheberValue: null })
    }
  }

  resetAllFilters() {
    this.setState({ entstehungFilter: null })
    this.setState({ entstehungValue: null })
    this.setState({ motivFilter: null })
    this.setState({ motivValue: null })
    this.setState({ artFilter: null })
    this.setState({ artValue: null })
    this.setState({ urheberFilter: null })
    this.setState({ urheberValue: null })
  }

  locateOnMap(targetName, imageType) {
    console.log('THIS IMAGES TYPE IS ', imageType)
    if (typeof this.props.getObjectWPInformationByName === 'function') {
      this.props.toggleImageSearch()
      this.props.toggleSideMenu()
      let currentObjekt = this.props.getObjectWPInformationByName(
        targetName,
        imageType
      )
      this.props.showOnMap(targetName, imageType)
    }
  }

  componentWillReceiveProps() {
    this.setState({ filteredImages: this.props.allImages })
  }

  componentDidMount() {
    this.getAllTaxonomyTerms('bild_motiv', 'motivTerms')
    this.getAllTaxonomyTerms('bild_art', 'artTerms')
    this.getAllTaxonomyTerms('bild_urheber', 'urheberTerms')
  }

  render() {
    //filter the images according to the set filters
    let outputImages = this.state.filteredImages
    if (this.state.entstehungFilter !== null) {
      switch (this.state.entstehungFilter) {
        case '1500–1700':
          outputImages = outputImages.filter(
            (img) =>
              img.acf.bild_entstehungszeit >= 1500 &&
              img.acf.bild_entstehungszeit <= 1700
          )
          break
        case '1700–1800':
          outputImages = outputImages.filter(
            (img) =>
              img.acf.bild_entstehungszeit >= 1700 &&
              img.acf.bild_entstehungszeit <= 1800
          )
          break
        case '1800–1830':
          outputImages = outputImages.filter(
            (img) =>
              img.acf.bild_entstehungszeit >= 1800 &&
              img.acf.bild_entstehungszeit <= 1830
          )
          break
        case '1830–1860':
          outputImages = outputImages.filter(
            (img) =>
              img.acf.bild_entstehungszeit >= 1830 &&
              img.acf.bild_entstehungszeit <= 1860
          )
          break
        case '1861':
          outputImages = outputImages.filter(
            (img) => img.acf.bild_entstehungszeit == 1861
          )
          break
        case '1862–1900':
          outputImages = outputImages.filter(
            (img) =>
              img.acf.bild_entstehungszeit >= 1862 &&
              img.acf.bild_entstehungszeit <= 1900
          )
          break
        case '1900–1950':
          console.log('FILTER 1900–1950')
          outputImages = outputImages.filter(
            (img) =>
              img.acf.bild_entstehungszeit >= 1900 &&
              img.acf.bild_entstehungszeit <= 1950
          )
          break
        case 'nach 1950':
          outputImages = outputImages.filter(
            (img) => img.acf.bild_entstehungszeit > 1950
          )
          break
        default:
          // no filter
          break
      }
    }
    if (this.state.motivFilter !== null) {
      outputImages = outputImages.filter(
        function (img) {
          if (img.acf.bild_motiv !== undefined) {
            return img.acf.bild_motiv.toString() === this.state.motivFilter
          }
        }.bind(this)
      )
    }
    if (this.state.artFilter !== null) {
      outputImages = outputImages.filter(
        function (img) {
          if (img.acf.bild_art !== undefined) {
            return img.acf.bild_art.toString() === this.state.artFilter
          }
        }.bind(this)
      )
    }
    if (this.state.urheberFilter !== null) {
      outputImages = outputImages.filter(
        function (img) {
          if (img.acf.bild_urheber !== undefined) {
            return img.acf.bild_urheber.toString() === this.state.urheberFilter
          }
        }.bind(this)
      )
    }

    outputImages = outputImages
      .sort((a, b) => a.title.rendered.localeCompare(b.title.rendered))
      .map(
        function (currImage) {
          let imageType = 'unknown'
          if (currImage.link.includes('backend/objekte')) {
            imageType = 'objekt'
          } else if (currImage.link.includes('backend/grafiken')) {
            imageType = 'grafik'
          } else if (currImage.link.includes('backend/brandstaetten')) {
            imageType = 'brandstätte'
          } else if (currImage.link.includes('backend/virtuelleansicht')) {
            imageType = 'ansicht'
          }

          return (
            currImage.media_details.sizes.medium &&
            !currImage.title.rendered.includes('exclude from imagesearch') && (
              <div key={currImage.id}>
                <div className='ui fluid card'>
                  <div
                    className='image'
                    onClick={() =>
                      this.props.openLightbox(
                        currImage.source_url,
                        currImage.caption.rendered.replace(
                          /(<([^>]+)>)/gi,
                          ''
                        ) /* all HTML Tags stripped */,
                        currImage.title.rendered
                      )
                    }
                  >
                    <img
                      src={
                        currImage.media_details.sizes.medium_large !== undefined
                          ? currImage.media_details.sizes.medium_large
                              .source_url
                          : currImage.media_details.sizes.medium.source_url
                      }
                      alt={currImage.slug}
                      className='thumb'
                    />
                  </div>
                  <div className='content'>
                    <div
                      className='header'
                      onClick={() =>
                        this.props.openLightbox(
                          currImage.source_url,
                          currImage.caption.rendered,
                          currImage.title.rendered
                        )
                      }
                    >
                      {currImage.title.rendered}
                    </div>
                    <div
                      className='show-full'
                      onClick={() =>
                        this.props.openLightbox(
                          currImage.source_url,
                          currImage.caption.rendered,
                          currImage.title.rendered
                        )
                      }
                    >
                      <i className='icon search small' />
                      Grossansicht
                    </div>
                  </div>
                  <div className='extra content'>
                    <div
                      className='show-onmap'
                      onClick={() =>
                        this.locateOnMap(currImage.title.rendered, imageType)
                      }
                    >
                      <i className='icon bullseye small' />
                      Auf der Karte zeigen
                    </div>
                  </div>
                </div>
              </div>
            )
          )
        }.bind(this)
      )

    return (
      <div className='image-results'>
        <div className='hint'>Bilder filtern:</div>
        <div className='filters'>
          <Dropdown
            placeholder='Entstehungszeit'
            selection
            clearable
            value={this.state.entstehungValue}
            options={this.state.entstehungTerms}
            onChange={this.onEntstehungChange}
          />
          <Dropdown
            placeholder='Motiv'
            selection
            clearable
            value={this.state.motivValue}
            options={this.state.motivTerms}
            onChange={this.onMotivChange}
          />
          <Dropdown
            placeholder='Art der Darstellung'
            selection
            clearable
            value={this.state.artValue}
            options={this.state.artTerms}
            onChange={this.onArtChange}
          />
          <Dropdown
            placeholder='Fotograf/Künstler'
            selection
            clearable
            value={this.state.urheberValue}
            options={this.state.urheberTerms}
            onChange={this.onUrheberChange}
          />
        </div>
        {/* console.log('outputImages: ', outputImages) */}
        {outputImages.length > 0 ? (
          <Masonry
            breakpointCols={breakpointColumnsObj}
            className='my-masonry-grid'
            columnClassName='my-masonry-grid_column'
            style={{ marginTop: '1.4em' }}
          >
            {outputImages}
          </Masonry>
        ) : (
          <p className='hint'>
            Die Filtereinstellungen liefern kein Resultat, wollen Sie{' '}
            <a href='#reset-filters' onClick={() => this.resetAllFilters()}>
              alle Filter zurücksetzen?
            </a>
          </p>
        )}
      </div>
    )
  }
}
