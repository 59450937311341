import React from 'react'
import PieChart from 'react-minimal-pie-chart'
import './styles.scss'

// https://www.javascriptstuff.com/detect-image-load/

export class PanIndicator extends React.Component<Props, State> {
  constructor(props) {
    super(props)
  }

  render() {
    const outerAngleFactor = 100 / (360 / this.props.totalDegrees)
    const outerStart = (180 - this.props.totalDegrees) / 2
    const innerDegrees =
      this.props.totalDegrees *
      (this.props.wrapperWidth / this.props.imageWidth)
    const innerAngleFactor = 100 / (360 / innerDegrees)
    const innerStart = (180 - innerDegrees) / 2
    const rotationFactor =
      this.props.xpos / 2 / (this.props.imageWidth - this.props.wrapperWidth)
    const rotationDegrees =
      (this.props.totalDegrees - innerDegrees) * rotationFactor
    const styleDegrees = 2 * rotationDegrees + this.props.totalDegrees / 2
    return (
      <div>
        <div className='logger'>
          {'xpos: ' +
            Math.round(this.props.xpos) +
            ' wrapperWidth: ' +
            this.props.wrapperWidth +
            ' imageWidth: ' +
            this.props.imageWidth +
            ' | ' +
            'totalDegrees: ' +
            this.props.totalDegrees +
            ' outerAngleFactor: ' +
            outerAngleFactor +
            ' innerDegrees: ' +
            innerDegrees +
            ' innerAngleFactor: ' +
            innerAngleFactor +
            ' rotationFactor: ' +
            rotationFactor +
            ' rotationDegrees: ' +
            rotationDegrees}
        </div>
        <div className='pan-indicator'>
          <div className='outer'>
            <PieChart
              data={[
                {
                  color: 'transparent',
                  title: 'Negative',
                  value: 100 - outerAngleFactor
                },
                {
                  color: '#FFF',
                  title: 'Segment',
                  value: outerAngleFactor
                }
              ]}
              lengthAngle={360}
              lineWidth={75}
              paddingAngle={0}
              radius={50}
              rounded={false}
              startAngle={-outerStart}
              viewBoxSize={[100, 100]}
            />
          </div>
          <div
            className='inner'
            style={{
              transform: 'rotate(' + -styleDegrees + 'deg)'
            }}
          >
            <PieChart
              data={[
                {
                  color: 'transparent',
                  title: 'Negative',
                  value: 100 - innerAngleFactor
                },
                {
                  color: '#FFF',
                  title: 'Segment',
                  value: innerAngleFactor
                }
              ]}
              lengthAngle={360}
              lineWidth={75}
              paddingAngle={0}
              radius={50}
              rounded={false}
              startAngle={-innerStart + innerDegrees / 2}
              viewBoxSize={[100, 100]}
            />
          </div>
        </div>
      </div>
    )
  }
}
