import React, { Component } from 'react'
import 'whatwg-fetch'
import mapboxgl from 'mapbox-gl'
import Dock from 'react-dock'
import renderHTML from 'react-render-html'
import { PhotoSwipe } from 'react-photoswipe'
import 'react-photoswipe/lib/photoswipe.css'
import { InfoDock } from './infodock'
import { TopMenu } from './topmenu'
import { SideMenu } from './sidemenu'
import { PanViewer } from './panviewer'
import { VideoScroller } from './videoscroller'
import { ImageSearch } from './imagesearch'
import { LightboxLink } from './lightboxlink'
import { renderToString } from 'react-dom/server'
import iconGebaeude from './images/gebaeude.svg'
import iconAnsicht from './images/ansicht.svg'
import iconBrandstaette from './images/brandstaette.svg'
import iconGrafik from './images/grafik.svg'

import 'semantic-ui-css/semantic.min.css'

import './mapbox-gl.css'
import './styles.scss'

let streetMarker = new mapboxgl.Marker({
  anchor: 'bottom',
  offset: [-5, 5],
})

mapboxgl.accessToken =
  'pk.eyJ1IjoibWFkbHV0eiIsImEiOiJjam5vYmgxM3MwMGx5M3BvOWlkNngxM3BzIn0.n8iPXjiKJEnR4IKbcE5chQ'

let lightboxOptions = {
  // http://photoswipe.com/documentation/options.html
  shareEl: false,
  zoomEl: true,
  fullscreenEl: false,
}

/* Three.js stuff */
// parameters to ensure the model is georeferenced correctly on the map
var modelOrigin = [9.06699, 47.04209]
var modelAltitude = 0
var modelRotate = [Math.PI / 2, 1.35, 0] // initially was [Math.PI / 2, 0, 0]

var modelAsMercatorCoordinate = mapboxgl.MercatorCoordinate.fromLngLat(
  modelOrigin,
  modelAltitude
)

// transformation parameters to position, rotate and scale the 3D model onto the map
var modelTransform = {
  translateX: modelAsMercatorCoordinate.x,
  translateY: modelAsMercatorCoordinate.y,
  translateZ: modelAsMercatorCoordinate.z,
  rotateX: modelRotate[0],
  rotateY: modelRotate[1],
  rotateZ: modelRotate[2],
  /* Since our 3D model is in real world meters, a scale transform needs to be
   * applied since the CustomLayerInterface expects units in MercatorCoordinates.
   */
  scale: modelAsMercatorCoordinate.meterInMercatorCoordinateUnits() / 0.5,
}

var THREE = window.THREE

// configuration of the custom layer for a 3D model per the CustomLayerInterface
var customLayer = {
  id: '3d-model',
  type: 'custom',
  renderingMode: '3d',
  onAdd: function (map, gl) {
    this.camera = new THREE.Camera()
    this.scene = new THREE.Scene()

    // create two three.js lights to illuminate the model
    var directionalLight = new THREE.DirectionalLight(0xffffff)
    directionalLight.position.set(0, -70, 100).normalize()
    this.scene.add(directionalLight)

    var directionalLight2 = new THREE.DirectionalLight(0xffffff)
    directionalLight2.position.set(0, 70, 100).normalize()
    this.scene.add(directionalLight2)

    // use the three.js GLTF loader to add the 3D model to the three.js scene
    var loader = new THREE.GLTFLoader()
    loader.load(
      './gltf/test0_0049.glb',
      function (gltf) {
        this.scene.add(gltf.scene)
      }.bind(this)
    )
    this.map = map

    // use the Mapbox GL JS map canvas for three.js
    this.renderer = new THREE.WebGLRenderer({
      canvas: map.getCanvas(),
      context: gl,
      antialias: true,
    })

    this.renderer.autoClear = false
  },
  render: function (gl, matrix) {
    var rotationX = new THREE.Matrix4().makeRotationAxis(
      new THREE.Vector3(1, 0, 0),
      modelTransform.rotateX
    )
    var rotationY = new THREE.Matrix4().makeRotationAxis(
      new THREE.Vector3(0, 1, 0),
      modelTransform.rotateY
    )
    var rotationZ = new THREE.Matrix4().makeRotationAxis(
      new THREE.Vector3(0, 0, 1),
      modelTransform.rotateZ
    )

    var m = new THREE.Matrix4().fromArray(matrix)
    var l = new THREE.Matrix4()
      .makeTranslation(
        modelTransform.translateX,
        modelTransform.translateY,
        modelTransform.translateZ
      )
      .scale(
        new THREE.Vector3(
          modelTransform.scale,
          -modelTransform.scale,
          modelTransform.scale
        )
      )
      .multiply(rotationX)
      .multiply(rotationY)
      .multiply(rotationZ)

    this.camera.projectionMatrix.elements = matrix
    this.camera.projectionMatrix = m.multiply(l)
    this.renderer.state.reset()
    this.renderer.render(this.scene, this.camera)
    this.map.triggerRepaint()
  },
}
/* Three.js END */

class App extends Component {
  constructor(props: Props) {
    super(props)

    this.state = {
      isDeveloperMode: false,
      objekte: [],
      virtuelleAnsichten: [],
      grafiken: [],
      brandstaetten: [],
      kapitel: [],
      pages: [],
      categories: [],
      featuresLoaded: false,
      mapFeatures: [],
      dataRouteObjekte:
        /*'https://altglarus.ch/backend/wp-json/objekte/v1/post',*/
        'https://altglarus.ch/backend/wp-json/wp/v2/objekt/?per_page=100', // https://stackoverflow.com/questions/35728943/wordpress-rest-api-v2-return-all-posts/35729286
      dataRouteVirtuelle:
        'https://altglarus.ch/backend/wp-json/wp/v2/virtuelleansicht/?per_page=100', // https://stackoverflow.com/questions/35728943/wordpress-rest-api-v2-return-all-posts/35729286
      dataRouteGrafiken:
        'https://altglarus.ch/backend/wp-json/wp/v2/grafik/?per_page=100', // https://stackoverflow.com/questions/35728943/wordpress-rest-api-v2-return-all-posts/35729286
      dataRouteBrandstaetten:
        'https://altglarus.ch/backend/wp-json/wp/v2/brandstaette/?per_page=100', // https://stackoverflow.com/questions/35728943/wordpress-rest-api-v2-return-all-posts/35729286
      dataRouteKapitel:
        'https://altglarus.ch/backend/wp-json/wp/v2/kapitel?filter[orderby]=menu_position&order=asc&per_page=100',
      dataRoutePages:
        'https://altglarus.ch/backend/wp-json/wp/v2/pages/?per_page=100',
      dataRouteCategories:
        'https://altglarus.ch/backend/wp-json/wp/v2/categories/?per_page=100',
      isMobile: window.innerWidth < 600,
      isTablet: window.innerWidth < 800,
      lng: 9.0659,
      lat: 47.0404,
      zoom: 15,
      dockIsVisible: false,
      menuIsVisible: false,
      imageSearchIsVisible: false,
      preLightboxElements: [],
      activeObject: {},
      ansichtenVisible: true,
      gebaeudeVisible: true,
      brandstaettenVisible: true,
      grafikenVisible: true,
      renderingVisible: true,
      carreeVisible: false,
      uebersichtBrandstaettenVisible: false,
      model3dVisible: false,
      lightboxOpen: false,
      lightboxItems: [
        {
          src: 'https://www.gl.ch/public/upload/assets/7114/Bevoelkerung.jpg',
          w: 900,
          h: 900,
          title: 'Image 1',
        },
      ],
      panviewerOpen: false,
      image1860: '',
      image2011: '',
      videoScrollIsOpen: false,
    }

    this.flyToLocation = this.flyToLocation.bind(this)
    this.getObjectWPInformationByName = this.getObjectWPInformationByName.bind(
      this
    )
    this.getObjectFeaturesByName = this.getObjectFeaturesByName.bind(this)
    this.getObjectCoordinates = this.getObjectCoordinates.bind(this)
    this.displayObjectInfo = this.displayObjectInfo.bind(this)
    this.displayChapter = this.displayChapter.bind(this)
    this.displayPage = this.displayPage.bind(this)
    this.openLightbox = this.openLightbox.bind(this)
    this.toggleAnsichten = this.toggleAnsichten.bind(this)
    this.toggleGebaeude = this.toggleGebaeude.bind(this)
    this.toggleBrandstaetten = this.toggleBrandstaetten.bind(this)
    this.toggleGrafiken = this.toggleGrafiken.bind(this)
    this.toggleRendering = this.toggleRendering.bind(this)
    this.setRenderingOpacity = this.setRenderingOpacity.bind(this)
    this.toggleCarree = this.toggleCarree.bind(this)
    this.toggleUebersichtBrandstaetten = this.toggleUebersichtBrandstaetten.bind(
      this
    )
    this.toggle3dModel = this.toggle3dModel.bind(this)
    this.locateUser = this.locateUser.bind(this)
    this.moveCustomMarker = this.moveCustomMarker.bind(this)
    this.toggleImageSearch = this.toggleImageSearch.bind(this)
    this.toggleSideMenu = this.toggleSideMenu.bind(this)
    this.openPanviewer = this.openPanviewer.bind(this)
    this.closePanviewer = this.closePanviewer.bind(this)
    this.openVideoScroll = this.openVideoScroll.bind(this)
    this.closeVideoScroll = this.closeVideoScroll.bind(this)
    this.openInfoDock = this.openInfoDock.bind(this)
    this.closeInfoDock = this.closeInfoDock.bind(this)
    this.showOnMap = this.showOnMap.bind(this)
    this.locateObject = this.locateObject.bind(this)
    this.getObjectsInCategory = this.getObjectsInCategory.bind(this)
    this.getCategoryName = this.getCategoryName.bind(this)
  }

  toggleAnsichten() {
    console.log('toggletoggleAnsichten')
    this.setState(
      { ansichtenVisible: !this.state.ansichtenVisible },
      () =>
        this.map.setLayoutProperty(
          'glarus-ansichten',
          'visibility',
          this.state.ansichtenVisible ? 'visible' : 'none'
        ),
      this.map.setPaintProperty('glarus-ansichten', 'icon-opacity', 1)
    )
  }

  toggleGebaeude() {
    console.log('toggletoggleGebaeude')
    this.setState(
      { gebaeudeVisible: !this.state.gebaeudeVisible },
      () =>
        this.map.setLayoutProperty(
          'glarus-objects',
          'visibility',
          this.state.gebaeudeVisible ? 'visible' : 'none'
        ),
      this.map.setPaintProperty('glarus-objects', 'icon-opacity', 1)
    )
  }

  toggleBrandstaetten() {
    console.log('toggleBrandstaetten')
    this.setState(
      { brandstaettenVisible: !this.state.brandstaettenVisible },
      () =>
        this.map.setLayoutProperty(
          'glarus-brandstaetten',
          'visibility',
          this.state.brandstaettenVisible ? 'visible' : 'none'
        ),
      this.map.setPaintProperty('glarus-brandstaetten', 'icon-opacity', 1)
    )
  }

  toggleGrafiken() {
    console.log('toggleGrafiken')
    this.setState(
      { grafikenVisible: !this.state.grafikenVisible },
      () =>
        this.map.setLayoutProperty(
          'glarus-grafiken',
          'visibility',
          this.state.grafikenVisible ? 'visible' : 'none'
        ),
      this.map.setPaintProperty('glarus-grafiken', 'icon-opacity', 1)
    )
  }

  toggleRendering() {
    console.log('toggleRendering')
    this.setState(
      { renderingVisible: !this.state.renderingVisible },
      () =>
        this.map.setLayoutProperty(
          'rendering1860',
          'visibility',
          this.state.renderingVisible ? 'visible' : 'none'
        ),
      this.map.setPaintProperty('rendering1860', 'raster-opacity', 1),
      this.map.setPaintProperty(
        'satellite',
        'raster-saturation',
        this.state.renderingVisible ? 0 : -1
      )
    )
  }
  setRenderingOpacity = (sliderValue) => {
    // map.setPaintProperty('chicago', 'raster-opacity', parseInt(e.target.value, 10) / 100);
    this.map.setPaintProperty(
      'rendering1860',
      'raster-opacity',
      parseInt(sliderValue, 10) / 100
    )
    if (parseInt(sliderValue) < 100) {
      this.map.setPaintProperty('satellite', 'raster-saturation', 0)
    } else {
      this.map.setPaintProperty('satellite', 'raster-saturation', -1)
    }
    console.log('opacity: ', sliderValue)
  }

  toggleCarree() {
    console.log('toggleCarree')
    this.setState({ carreeVisible: !this.state.carreeVisible }, () =>
      this.map.setLayoutProperty(
        'carree',
        'visibility',
        this.state.carreeVisible ? 'visible' : 'none'
      )
    )
    if (!this.state.carreeVisible) {
      if (!this.state.renderingVisible) {
        this.toggleRendering()
      }
      //this.flyToLocation([9.0656, 47.0416], this.state.isMobile ? 14 : 14.75)
    }
  }

  toggleUebersichtBrandstaetten() {
    console.log('toggleUebersichtBrandstaetten')
    this.setState(
      {
        uebersichtBrandstaettenVisible: !this.state
          .uebersichtBrandstaettenVisible,
      },
      () =>
        this.map.setLayoutProperty(
          'uebersichtBrandstaetten',
          'visibility',
          this.state.uebersichtBrandstaettenVisible ? 'visible' : 'none'
        )
    )
    if (!this.state.uebersichtBrandstaettenVisible) {
      if (!this.state.renderingVisible) {
        this.toggleRendering()
      }
      //this.flyToLocation([9.0656, 47.0416], this.state.isMobile ? 14 : 14.75)
    }
  }

  toggle3dModel() {
    console.log('toggle3dModel')
    this.setState({ model3dVisible: !this.state.model3dVisible }, () => {
      this.map.setLayoutProperty(
        '3d-model',
        'visibility',
        this.state.model3dVisible ? 'visible' : 'none'
      )
      if (this.state.model3dVisible) {
        this.map.setLayoutProperty('satellite', 'visibility', 'none')
        this.map.setPitch(60)
        if (this.state.renderingVisible) {
          this.toggleRendering()
        }
        if (this.state.carreeVisible) {
          this.toggleCarree()
        }
        if (this.state.uebersichtBrandstaettenVisible) {
          this.toggleUebersichtBrandstaettenVisible()
        }
        this.flyToLocation([9.0636, 47.0423], this.state.isMobile ? 16 : 16.5)
      } else {
        this.map.setLayoutProperty('satellite', 'visibility', 'visible')
        this.map.setPitch(0)
      }
    })
  }

  toggleImageSearch() {
    this.setState({ imageSearchIsVisible: !this.state.imageSearchIsVisible })
  }

  toggleSideMenu() {
    this.setState({ menuIsVisible: !this.state.menuIsVisible })
  }

  flyToLocation(coordinates, zoom) {
    console.log('>>>>>>>>> flyToLocation: ')
    console.log(coordinates)
    // get centered coordinates
    let long = coordinates[0]
    let lat = coordinates[1]

    if (coordinates[0] === null && coordinates[1] === null) {
      //ERROR ZOOM OUT TO GLARUS
      this.map.flyTo({
        center: [9.062, 47.04],
        zoom: 13.58,
      })
    } else {
      // FLY TO LOCATION
      this.map.flyTo({
        center: [long, lat],
        offset: this.state.isMobile ? [0, -(window.innerHeight / 4)] : [0, 0],
        zoom: zoom,
      })
    }
  }

  moveCustomMarker(targetMarker, targetCoordinates, targetName) {
    //console.log('>>>>>>>>>>>> move marker')
    targetMarker.remove()
    targetMarker
      .setLngLat(targetCoordinates)
      /*
      .setPopup(
        new mapboxgl.Popup({ offset: [-5, -40] }) // add popups
          .setHTML('<h3>' + targetName + '</h3>')
      )
      */
      .addTo(this.map)
  }

  getObjectWPInformationByName(objectName, type) {
    // handle ampersand: &
    console.log('getObjectWPInformationByName of: ')
    console.log(objectName + ' (Type: ' + type + ')')
    let cleanedObjectName = objectName.toString().split('&#038;').join('&')
    cleanedObjectName = cleanedObjectName.toString().split('&').join('&#038;')
    console.log('###### WP INFORMATION #######')

    let selectedFeature = {}

    if (type === 'ansicht') {
      console.log(this.state.virtuelleAnsichten)
      selectedFeature = this.state.virtuelleAnsichten.filter(function (
        ansicht
      ) {
        return (
          ansicht.title.rendered.toString().toLowerCase() ===
          cleanedObjectName.toString().toLowerCase()
        )
      })
    } else if (type === 'grafik') {
      console.log(this.state.grafiken)
      selectedFeature = this.state.grafiken.filter(function (grafik) {
        return (
          grafik.title.rendered.toString().toLowerCase() ===
          cleanedObjectName.toString().toLowerCase()
        )
      })
    } else if (type === 'brandstätte') {
      console.log(this.state.brandstaetten)
      selectedFeature = this.state.brandstaetten.filter(function (
        brandstaette
      ) {
        return (
          brandstaette.title.rendered.toString().toLowerCase() ===
          cleanedObjectName.toString().toLowerCase()
        )
      })
    } else {
      console.log(this.state.objekte)
      selectedFeature = this.state.objekte.filter(function (objekt) {
        return (
          objekt.title.rendered.toString().toLowerCase() ===
          cleanedObjectName.toString().toLowerCase()
        )
      })
      /* console.log(selectedFeature) */
    }
    return selectedFeature
  }

  getObjectFeaturesByName(objectName) {
    // handle ampersand: &
    let cleanedObjectName = objectName.toString().split('&#038;').join('&')
    console.log('getObjectFeaturesByName of: ', cleanedObjectName)
    console.log('###### MAP FEATURES #######')
    console.log(this.state.mapFeatures)

    let selectedFeature = this.state.mapFeatures.filter(function (feature) {
      if (feature.properties.title) {
        return (
          feature.properties.title.toString().toLowerCase() ===
          cleanedObjectName.toString().toLowerCase()
        )
      } else {
        console.log(
          'MAD: Untitled feature on Mapbox Dataset:',
          feature.properties
        )
      }
    })
    return selectedFeature
  }

  getObjectCoordinates(feature) {
    console.log('>>>>> getObjectCoordinates <<<<<<')
    console.log(feature[0])
    if (feature && feature.length > 0) {
      return [
        feature[0].geometry.coordinates[0],
        feature[0].geometry.coordinates[1],
      ]
    } else {
      //show error info
      let errorFeatures = {
        title: {
          rendered: '⚠️ Fehler',
        },
        content: {
          rendered: 'Objekt konnte nicht geladen werden!',
        },
        image: '',
      }
      this.setState(
        // NO COORDINATES -> DISPLAY ERROR
        { activeObject: errorFeatures },
        () => this.setState({ dockIsVisible: true }),
        console.log('activeObject: ', this.state.activeObject)
      )
      return [null, null]
    }
  }

  displayObjectInfo(selectedFeature) {
    console.log('---> DISPLAY OBJECT INFO <---')

    // display chapter info
    // REFACTOR THIS?? -> PASSING PROPS TO DOCK COMPONENT
    //console.log('selectedFeature:', selectedFeature)
    if (selectedFeature && selectedFeature.length > 0) {
      this.setState(
        { activeObject: selectedFeature[0] },
        () => this.setState({ dockIsVisible: true }),
        console.log('activeObject: ', this.state.activeObject),
        console.log('type: ', this.state.activeObject.type)
      )
    } else {
      //show error info
      let errorFeatures = {
        title: {
          rendered: '⚠️ Fehler',
        },
        content: {
          rendered: 'Objekt konnte nicht geladen werden!',
        },
        image: '',
      }
      this.setState(
        { activeObject: errorFeatures },
        () => this.setState({ dockIsVisible: true }),
        console.log('activeObject: ', this.state.activeObject)
      )
    }
    // END REFACTOR

    if (this.state.menuIsVisible && this.state.isMobile) {
      this.setState({ menuIsVisible: false })
    }
  }

  displayChapter(selectedChapter) {
    console.log('---> DISPLAY CHAPTER <---')
    console.log(selectedChapter)
    //this.setState({ dockIsVisible: true })

    //show loading info
    let loadingFeatures = {
      title: {
        rendered: 'Kapitel',
      },
      content: {
        rendered: 'Wird geladen...',
      },
      image: '',
    }
    this.setState(
      { activeObject: loadingFeatures },
      () =>
        // display chapter info
        // REFACTOR THIS?? -> PASSING PROPS TO DOCK COMPONENT
        this.setState({ activeObject: selectedChapter }, () =>
          this.setState({ dockIsVisible: true })
        ),
      console.log('activeObject: ', this.state.activeObject)
      // END REFACTOR
    )
    if (this.state.menuIsVisible && this.state.isMobile) {
      this.setState({ menuIsVisible: false })
    }
  }

  displayPage(selectedPage) {
    console.log('---> DISPLAY PAGE <---')
    console.log(selectedPage)
    //this.setState({ dockIsVisible: true })

    //show loading info
    let loadingFeatures = {
      title: {
        rendered: 'Seite',
      },
      content: {
        rendered: 'Wird geladen...',
      },
      image: '',
    }
    this.setState(
      { activeObject: loadingFeatures },
      () =>
        // display page content
        // REFACTOR THIS?? -> PASSING PROPS TO DOCK COMPONENT
        this.setState({ activeObject: selectedPage }, () =>
          this.setState({ dockIsVisible: true })
        ),
      console.log('activeObject: ', this.state.activeObject)
      // END REFACTOR
    )
    if (this.state.menuIsVisible && this.state.isMobile) {
      this.setState({ menuIsVisible: false })
    }
  }

  openLightbox(images, objektCaption, objektTitle) {
    console.log(images)

    // https://github.com/dimsemenov/PhotoSwipe/issues/796
    /*
    let img = new Image()
    img.onload = function () { // will get size after load
      console.log('IMAGE WIDTH: ', this.width)
      console.log('IMAGE HEIGHT: ', this.height)
      //item.w = this.width // set image width
      //item.h = this.height // set image height
      //gallery.invalidateCurrItems() // reinit Items
      //gallery.updateSize(true) // reinit Items
    }
    img.src = images // let's download image
    */

    let img = new Image()
    img.src = images
    let whatever = (
      <LightboxLink
        objektTitle={objektTitle}
        showOnMap={this.showOnMap}
        getObjectWPInformationByName={this.getObjectWPInformationByName}
        displayObjectInfo={this.displayObjectInfo}
        getObjectFeaturesByName={this.getObjectFeaturesByName}
        getObjectCoordinates={this.getObjectCoordinates}
        flyToLocation={this.flyToLocation}
        activeObject={this.state.activeObject}
      />
    )
    let displayCaption = ''
    if (objektTitle !== undefined) {
      displayCaption = objektCaption // commented out because of auto click triggering on render (see lightboxLink compoenent)  + '<br />' + renderToString(whatever)
    } else {
      displayCaption = objektCaption
    }
    img.onload = function () {
      // console.log('WIDTH: ' + img.width + ' | HEIGHT: ' + img.height)
      this.setState({
        lightboxItems: [
          {
            src: images,
            w: img.width,
            h: img.height,
            title: displayCaption,
          },
        ],
      })
      //save dock/menu state for later
      this.setState({
        preLightboxElements: [
          {
            dock: this.state.dockIsVisible,
            menu: this.state.menuIsVisible,
            imageSearch: this.state.imageSearchIsVisible,
          },
        ],
        dockIsVisible: false,
        menuIsVisible: false,
        imageSearchIsVisible: false,
        lightboxOpen: true,
      })
    }.bind(this)
  }

  closeLightbox() {
    this.setState({
      lightboxOpen: false,
      dockIsVisible: this.state.preLightboxElements[0]['dock'],
      menuIsVisible: this.state.preLightboxElements[0]['menu'],
      imageSearchIsVisible: this.state.preLightboxElements[0]['imageSearch'],
    })
  }

  openPanviewer(currentObjekt) {
    let image1860 = currentObjekt[0].acf.vergleich_bilder[0].vergleich_bild.url
    let image2011 = currentObjekt[0].acf.vergleich_bilder[1].vergleich_bild.url
    this.setState({
      image1860: image1860,
      image2011: image2011,
      panviewerOpen: true,
      dockIsVisible: false,
      menuIsVisible: false,
      imageSearchIsVisible: false,
    })
  }

  closePanviewer() {
    this.setState({
      panviewerOpen: false,
    })
  }

  openVideoScroll() {
    this.setState({
      videoScrollIsOpen: true,
      dockIsVisible: false,
      menuIsVisible: false,
      imageSearchIsVisible: false,
    })
  }

  closeVideoScroll() {
    this.setState({
      videoScrollIsOpen: false,
    })
  }

  closeInfoDock() {
    this.setState({
      dockIsVisible: false,
    })
  }
  openInfoDock() {
    this.setState({
      dockIsVisible: true,
    })
  }

  showOnMap(targetName, type) {
    console.log('>>> SHOW ON MAP <<<')
    /*
      this.toggleImageSearch()
      this.toggleSideMenu()
      */
    let currentFeatures = this.getObjectFeaturesByName(targetName)
    let currentTyp = currentFeatures[0].properties.typ
    console.log('currentTyp:', currentTyp)
    if (currentTyp !== 'ansicht') {
      let currentObjekt = this.getObjectWPInformationByName(targetName, type)
      this.displayObjectInfo(currentObjekt)
    }

    switch (currentTyp) {
      case 'gebäude':
        if (!this.state.gebaeudeVisible) {
          this.toggleGebaeude()
        }
        break
      case 'ansicht':
        if (!this.state.ansichtenVisible) {
          this.toggleAnsichten()
        }
        break
      case 'grafik':
        if (!this.state.grafikenVisible) {
          this.toggleGrafiken()
        }
        break
      case 'brandstätte':
        if (!this.state.brandstaettenVisible) {
          this.toggleBrandstaetten()
        }
        break
      default:
        // nothing
        break
    }

    let currentCoordinates = this.getObjectCoordinates(currentFeatures)
    this.flyToLocation(currentCoordinates, this.isMobile ? 17 : 17.75)

    //mini popup
    this.map.getCanvas().style.cursor = 'pointer'
    // Ensure that if the map is zoomed out such that multiple
    // copies of the feature are visible, the popup appears
    // over the copy being pointed to.
    /*
    while (Math.abs(e.lngLat.lng - currentCoordinates[0]) > 180) {
      currentCoordinates[0] += e.lngLat.lng > currentCoordinates[0] ? 360 : -360
      */
    //remove old popups
    if (this.popup !== null) {
      this.popup.remove()
    }
    // Populate the popup and set its coordinates
    // based on the feature found.
    this.popup = new mapboxgl.Popup({ offset: [0, -15] })
      .setLngLat(currentCoordinates)
      .setHTML('<h3>' + targetName + '</h3>')
      .addTo(this.map)
  }

  locateObject(targetName) {
    let currentFeatures = this.getObjectFeaturesByName(targetName)
    let currentTyp = currentFeatures[0].properties.typ
    //console.log('currentTyp:',currentTyp)
    switch (currentTyp) {
      case 'gebäude':
        if (!this.state.gebaeudeVisible) {
          this.toggleGebaeude()
        }
        break
      case 'ansicht':
        if (!this.state.ansichtenVisible) {
          this.toggleAnsichten()
        }
        break
      case 'grafik':
        if (!this.state.grafikenVisible) {
          this.toggleGrafiken()
        }
        break
      case 'brandstätte':
        if (!this.state.brandstaettenVisible) {
          this.toggleBrandstaetten()
        }
        break
      default:
        // nothing
        break
    }

    let currentCoordinates = this.getObjectCoordinates(currentFeatures)
    this.flyToLocation(currentCoordinates, this.isMobile ? 17 : 17.75)

    //mini popup
    this.map.getCanvas().style.cursor = 'pointer'
    // Ensure that if the map is zoomed out such that multiple
    // copies of the feature are visible, the popup appears
    // over the copy being pointed to.
    /*
    while (Math.abs(e.lngLat.lng - currentCoordinates[0]) > 180) {
      currentCoordinates[0] += e.lngLat.lng > currentCoordinates[0] ? 360 : -360
      */
    // Populate the popup and set its coordinates
    // based on the feature found.
    this.popup = new mapboxgl.Popup({ offset: [0, -15] })
      .setLngLat(currentCoordinates)
      .setHTML('<h3>' + targetName + '</h3>')
      .addTo(this.map)
  }

  locateUser() {
    const map = this.map
    const location = window.navigator && window.navigator.geolocation
    if (location) {
      location.getCurrentPosition(
        (position) => {
          if (
            position.coords.longitude >= 9.0 &&
            position.coords.longitude <= 9.1 &&
            position.coords.latitude >= 47.0 &&
            position.coords.latitude <= 47.1
          ) {
            console.log(
              '⌾ user is in bounds: ',
              position.coords.latitude,
              '| ',
              position.coords.latitude
            )
            // Add geolocate control to the map.
            let geolocate = new mapboxgl.GeolocateControl({
              positionOptions: {
                enableHighAccuracy: true,
                watchPosition: true,
              },
            })
            map.addControl(geolocate)
          } else {
            console.log('⌾ user is out of bounds')
          }
        },
        (error) => {
          console.log('user location not trackable')
        }
      )
    }
  }

  getObjectsInCategory(catID) {
    let objekteAndVirtuelle = this.state.virtuelleAnsichten.concat(
      this.state.objekte
    )
    let filtered = objekteAndVirtuelle.filter(function (el) {
      return el.categories.includes(catID)
    })
    return filtered
  }

  getCategoryName(catID) {
    let targetCategory = this.state.categories.find((obj) => obj.id === catID)
    return targetCategory ? targetCategory.name : 'category not found'
  }

  componentDidMount() {
    /* IS THIS DEVELOPMENT MODE ON LOCAL HOST? */
    if (
      window.location.hostname === 'localhost' ||
      window.location.hostname === '127.0.0.1'
    ) {
      // console.log('DEVELOPMENT MODE')
      this.setState({ isDeveloperMode: true })
    }

    /* WP OBJEKTE */
    fetch(this.state.dataRouteObjekte)
      .then((response) => {
        if (response.ok) {
          return response.json()
        } else {
          throw new Error("WP-Server response wasn't OK")
        }
      })
      .then((responseData) => {
        this.setState({ objekte: responseData }, () =>
          console.log('OBJEKTE:::::', this.state.objekte)
        )
      })

    /* WP VIRTUELLE ANSICHTEN */
    fetch(this.state.dataRouteVirtuelle)
      .then((response) => {
        if (response.ok) {
          return response.json()
        } else {
          throw new Error("WP-Server response wasn't OK")
        }
      })
      .then((responseData) => {
        this.setState({ virtuelleAnsichten: responseData }, () =>
          console.log('VIRTUELLE ANSICHTEN:::::', this.state.virtuelleAnsichten)
        )
      })

    /* WP GRAFIKEN & GEMÄLDE */
    fetch(this.state.dataRouteGrafiken)
      .then((response) => {
        if (response.ok) {
          return response.json()
        } else {
          throw new Error("WP-Server response wasn't OK")
        }
      })
      .then((responseData) => {
        this.setState({ grafiken: responseData }, () =>
          console.log('GRAFIKEN:::::', this.state.grafiken)
        )
      })

    /* WP FOTOS DER BRANDSTAETTE */
    fetch(this.state.dataRouteBrandstaetten)
      .then((response) => {
        if (response.ok) {
          return response.json()
        } else {
          throw new Error("WP-Server response wasn't OK")
        }
      })
      .then((responseData) => {
        this.setState({ brandstaetten: responseData }, () =>
          console.log('BRANDSTAETTEN:::::', this.state.brandstaetten)
        )
      })

    /* WP KAPITEL  */
    fetch(this.state.dataRouteKapitel)
      .then((response) => {
        if (response.ok) {
          return response.json()
        } else {
          throw new Error("WP-Server response wasn't OK")
        }
      })
      .then((responseData) => {
        this.setState({ kapitel: responseData }, () =>
          console.log('KAPITEL:::::', this.state.kapitel)
        )
      })

    /* WP PAGES  */
    fetch(this.state.dataRoutePages)
      .then((response) => {
        if (response.ok) {
          return response.json()
        } else {
          throw new Error("WP-Server response wasn't OK")
        }
      })
      .then((responseData) => {
        this.setState({ pages: responseData }, () =>
          console.log('PAGES:::::', this.state.pages)
        )
      })

    /* WP CATEGORIES  */
    fetch(this.state.dataRouteCategories)
      .then((response) => {
        if (response.ok) {
          return response.json()
        } else {
          throw new Error("WP-Server response wasn't OK")
        }
      })
      .then((responseData) => {
        this.setState({ categories: responseData }, () =>
          console.log('CATEGORIES:::::', this.state.categories)
        )
      })

    /* MAPBOX STUFF */
    const { lng, lat, zoom } = this.state

    const map = new mapboxgl.Map({
      container: this.mapContainer,
      type: 'raster',
      //style: 'mapbox://styles/mapbox/light-v10',
      //style: 'mapbox://styles/mapbox/dark-v10',
      style: 'mapbox://styles/madlutz/ck16j0jle2tgt1cmhbgouxoa0',
      center: [lng, lat],
      zoom,
      minZoom: 12,
      maxZoom: 17,
      maxBounds: [
        [8.8, 46.82],
        [9.32, 47.26],
      ],
      bearing: -120,
      antialias: true,
    })

    this.map = map
    this.popup = null

    // Add zoom and rotation controls to the map.
    map.addControl(new mapboxgl.NavigationControl())

    // get all (visible) markers
    map.on(
      'load',
      function (e) {
        console.log('≈≈≈≈ MAP LOADED ≈≈≈≈')
        /*
        let vectorLayers = map.getSource('composite').vectorLayerIds
        console.log('--- vectorLayers ---')
        console.log(vectorLayers)
        */

        /* add Three.js Layer */
        map.addLayer(customLayer)
        map.setLayoutProperty('3d-model', 'visibility', 'none')

        map.addLayer(
          {
            id: 'satellite',
            source: {
              type: 'raster',
              url: 'mapbox://mapbox.satellite',
              tileSize: 256,
            },
            type: 'raster',
          },
          '3d-model'
        )
        map.setPaintProperty('satellite', 'raster-saturation', -1)

        map.addLayer(
          {
            id: 'rendering1860',
            type: 'raster',
            source: {
              type: 'raster',
              tiles: [
                'https://api.mapbox.com/v4/madlutz.5hula1e6/{z}/{x}/{y}@2x.png?access_token=pk.eyJ1IjoibWFkbHV0eiIsImEiOiJjam5vYmgxM3MwMGx5M3BvOWlkNngxM3BzIn0.n8iPXjiKJEnR4IKbcE5chQ',
              ],
              tileSize: 256,
            },
          },
          '3d-model'
        )

        map.addLayer(
          {
            id: 'carree',
            type: 'raster',
            source: {
              type: 'raster',
              tiles: [
                'https://api.mapbox.com/v4/madlutz.73njfwf4/{z}/{x}/{y}@2x.png?access_token=pk.eyJ1IjoibWFkbHV0eiIsImEiOiJjam5vYmgxM3MwMGx5M3BvOWlkNngxM3BzIn0.n8iPXjiKJEnR4IKbcE5chQ',
              ],
              tileSize: 256,
            },
          },
          '3d-model'
        )
        map.setLayoutProperty('carree', 'visibility', 'none')

        map.addLayer(
          {
            id: 'uebersichtBrandstaetten',
            type: 'raster',
            source: {
              type: 'raster',
              tiles: [
                'https://api.mapbox.com/v4/madlutz.cjy71g3x/{z}/{x}/{y}@2x.png?access_token=pk.eyJ1IjoibWFkbHV0eiIsImEiOiJjam5vYmgxM3MwMGx5M3BvOWlkNngxM3BzIn0.n8iPXjiKJEnR4IKbcE5chQ',
              ],
              tileSize: 256,
            },
          },
          '3d-model'
        )
        map.setLayoutProperty('uebersichtBrandstaetten', 'visibility', 'none')

        let imgGebaeude = new Image(40, 40)
        imgGebaeude.onload = function () {
          map.addImage('gebaeude', imgGebaeude)

          map.addLayer({
            id: 'glarus-objects', //tileset created in mapbox based on dataset 'glarus test'
            type: 'symbol',
            source: {
              type: 'vector',
              url: 'mapbox://madlutz.cjnocc1lj0djp2wmikyx9ua8i-9bz8t',
            },
            'source-layer': 'glarus-objects',
            layout: {
              'icon-image': [
                'match',
                ['get', 'typ'],
                'gebäude',
                'gebaeude',
                /* other */ 'notset',
              ],
              'icon-size': this.state.isMobile ? 0.75 : 0.6,
              'icon-allow-overlap': true,
            },
            paint: {
              'icon-opacity': 0, // this will be set to 1, when all featuresloaded
            },
          })
        }.bind(this)
        imgGebaeude.src = iconGebaeude

        let imgAnsicht = new Image(40, 40)
        imgAnsicht.onload = function () {
          map.addImage('ansicht', imgAnsicht)

          map.addLayer({
            id: 'glarus-ansichten', //tileset created in mapbox based on dataset 'glarus test'
            type: 'symbol',
            source: {
              type: 'vector',
              url: 'mapbox://madlutz.cjnocc1lj0djp2wmikyx9ua8i-9bz8t',
            },
            'source-layer': 'glarus-objects',
            layout: {
              'icon-image': [
                'match',
                ['get', 'typ'],
                'ansicht',
                'ansicht',
                /* other */ 'notset',
              ],
              'icon-size': this.state.isMobile ? 0.75 : 0.6,
              'icon-allow-overlap': true,
            },
            paint: {
              'icon-opacity': 0, // this will be set to 1, when all featuresloaded
            },
          })
        }.bind(this)
        imgAnsicht.src = iconAnsicht

        let imgBrandstaette = new Image(40, 40)
        imgBrandstaette.onload = function () {
          map.addImage('brandstaette', imgBrandstaette)

          map.addLayer({
            id: 'glarus-brandstaetten', //tileset created in mapbox based on dataset 'glarus test'
            type: 'symbol',
            source: {
              type: 'vector',
              url: 'mapbox://madlutz.cjnocc1lj0djp2wmikyx9ua8i-9bz8t',
            },
            'source-layer': 'glarus-objects',
            layout: {
              'icon-image': [
                'match',
                ['get', 'typ'],
                'brandstätte',
                'brandstaette',
                /* other */ 'notset',
              ],
              'icon-size': this.state.isMobile ? 0.75 : 0.6,
              'icon-allow-overlap': true,
            },
            paint: {
              'icon-opacity': 0, // this will be set to 1, when all featuresloaded
            },
          })
        }.bind(this)
        imgBrandstaette.src = iconBrandstaette

        let imgGrafik = new Image(40, 40)
        imgGrafik.onload = function () {
          map.addImage('grafik', imgGrafik)

          map.addLayer({
            id: 'glarus-grafiken', //tileset created in mapbox based on dataset 'glarus test'
            type: 'symbol',
            source: {
              type: 'vector',
              url: 'mapbox://madlutz.cjnocc1lj0djp2wmikyx9ua8i-9bz8t',
            },
            'source-layer': 'glarus-objects',
            layout: {
              'icon-image': [
                'match',
                ['get', 'typ'],
                'grafik',
                'grafik',
                /* other */ 'notset',
              ],
              'icon-size': this.state.isMobile ? 0.75 : 0.6,
              'icon-allow-overlap': true,
            },
            paint: {
              'icon-opacity': 0, // this will be set to 1, when all featuresloaded
            },
          })
        }.bind(this)
        imgGrafik.src = iconGrafik
      }.bind(this)
    )

    map.on(
      'render',
      function () {
        //console.log('RRRRRENDERED')
        if (!map.loaded() || this.state.featuresLoaded) return
        this.setState({
          mapFeatures: map.querySourceFeatures('glarus-objects', {
            sourceLayer: 'glarus-objects',
          }),
        })
        if (this.state.mapFeatures.length > 0) {
          console.log('featuresLoaded')
          this.toggleGebaeude()
          this.toggleAnsichten()
          this.toggleGrafiken()
          this.toggleBrandstaetten()
          this.setState({ featuresLoaded: true })
        }
      }.bind(this)
    )

    // open dock on click
    map.on(
      'click',
      function (e) {
        let features = map.queryRenderedFeatures(e.point, {
          layers: [
            'glarus-objects',
            //'glarus-ansichten',
            'glarus-brandstaetten',
            'glarus-grafiken',
          ], // replace this with the name of the layer
        })

        let ansichtenFeatures = map.queryRenderedFeatures(e.point, {
          layers: ['glarus-ansichten'], // replace this with the name of the layer
        })

        // clicked on ansicht?
        if (ansichtenFeatures.length) {
          console.log(
            'VIRTUELLE ANSICHT:',
            ansichtenFeatures[0].properties.title
          )
          let feature = ansichtenFeatures[0]
          // console.log('feature: ', feature)
          let currentObjekt = this.getObjectWPInformationByName(
            feature.properties.title,
            'ansicht'
          )
          console.log('currentObjekt: ', currentObjekt)
          if (currentObjekt[0] !== undefined) {
            if (currentObjekt[0].acf !== undefined) {
              if (currentObjekt[0].acf.vergleich_bilder) {
                //comparison pano
                this.openPanviewer(currentObjekt)
              } else if (currentObjekt[0].acf.rendering_bild) {
                //rendering
                this.openLightbox(
                  currentObjekt[0].acf.rendering_bild.url,
                  currentObjekt[0].acf.rendering_bild.description,
                  currentObjekt[0].title.rendered
                )
              } else {
                // ↑ SET A CONDITION ABOVE: WP SEQUENCE NAME or something...
                // rundumsicht image scroll
                this.openVideoScroll()
              }
            }
          }
          return
        } else if (features.length) {
          //DEFAULT BEHAVIOUR WHEN CLICKED ON MARKER
          // display object info & recenter
          let feature = features[0]
          let currentObjekt = this.getObjectWPInformationByName(
            feature.properties.title,
            feature.properties.typ
          )
          if (this.state.isMobile) {
            map.getCanvas().style.cursor = 'pointer'
            let coordinates = features[0].geometry.coordinates.slice()
            let title = features[0].properties.title
            console.log(title)
          }
          this.displayObjectInfo(currentObjekt)
        } else {
          // no marker clicked, just clicked on map...
          this.setState({ dockIsVisible: false })
          this.setState({ menuIsVisible: false })
          this.setState({ imageSearchIsVisible: false })
          return
        }
      }.bind(this)
    )

    map.on(
      'mouseenter',
      'glarus-objects',
      function (e) {
        map.getCanvas().style.cursor = 'pointer'

        let coordinates = e.features[0].geometry.coordinates.slice()
        let title = e.features[0].properties.title
        console.log(title)

        // Ensure that if the map is zoomed out such that multiple
        // copies of the feature are visible, the popup appears
        // over the copy being pointed to.
        while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
          coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360
        }
        //remove old popups
        if (this.popup !== null) {
          this.popup.remove()
        }
        // Populate the popup and set its coordinates
        // based on the feature found.
        this.popup = new mapboxgl.Popup({ offset: [0, -15] })
          .setLngLat(coordinates)
          .setHTML('<h3>' + title + '</h3>')
          .addTo(map)
      }.bind(this)
    )

    map.on(
      'mouseenter',
      'glarus-ansichten',
      function (e) {
        map.getCanvas().style.cursor = 'pointer'

        let coordinates = e.features[0].geometry.coordinates.slice()
        let title = e.features[0].properties.title
        console.log(title)

        // Ensure that if the map is zoomed out such that multiple
        // copies of the feature are visible, the popup appears
        // over the copy being pointed to.
        while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
          coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360
        }
        //remove old popups
        if (this.popup !== null) {
          this.popup.remove()
        }
        // Populate the popup and set its coordinates
        // based on the feature found.
        this.popup = new mapboxgl.Popup({ offset: [0, -15] })
          .setLngLat(coordinates)
          .setHTML('<h3>' + title + '</h3>')
          .addTo(map)
      }.bind(this)
    )

    map.on(
      'mouseenter',
      'glarus-brandstaetten',
      function (e) {
        map.getCanvas().style.cursor = 'pointer'

        let coordinates = e.features[0].geometry.coordinates.slice()
        let title = e.features[0].properties.title
        console.log(title)

        // Ensure that if the map is zoomed out such that multiple
        // copies of the feature are visible, the popup appears
        // over the copy being pointed to.
        while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
          coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360
        }
        //remove old popups
        if (this.popup !== null) {
          this.popup.remove()
        }
        // Populate the popup and set its coordinates
        // based on the feature found.
        this.popup = new mapboxgl.Popup({ offset: [0, -15] })
          .setLngLat(coordinates)
          .setHTML('<h3>' + title + '</h3>')
          .addTo(map)
      }.bind(this)
    )

    map.on(
      'mouseenter',
      'glarus-grafiken',
      function (e) {
        map.getCanvas().style.cursor = 'pointer'

        let coordinates = e.features[0].geometry.coordinates.slice()
        let title = e.features[0].properties.title
        console.log(title)

        // Ensure that if the map is zoomed out such that multiple
        // copies of the feature are visible, the popup appears
        // over the copy being pointed to.
        while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
          coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360
        }
        //remove old popups
        if (this.popup !== null) {
          this.popup.remove()
        }
        // Populate the popup and set its coordinates
        // based on the feature found.
        this.popup = new mapboxgl.Popup({ offset: [0, -15] })
          .setLngLat(coordinates)
          .setHTML('<h3>' + title + '</h3>')
          .addTo(map)
      }.bind(this)
    )

    map.on(
      'mouseleave',
      'glarus-ansichten',
      function (e) {
        map.getCanvas().style.cursor = ''
        this.popup.remove()
      }.bind(this)
    )

    map.on(
      'mouseleave',
      'glarus-objects',
      function (e) {
        map.getCanvas().style.cursor = ''
        this.popup.remove()
      }.bind(this)
    )

    map.on(
      'mouseleave',
      'glarus-brandstaetten',
      function (e) {
        map.getCanvas().style.cursor = ''
        this.popup.remove()
      }.bind(this)
    )

    map.on(
      'mouseleave',
      'glarus-grafiken',
      function (e) {
        map.getCanvas().style.cursor = ''
        this.popup.remove()
      }.bind(this)
    )

    // display current location data
    map.on('move', () => {
      const { lng, lat } = map.getCenter()

      this.setState({
        lng: lng.toFixed(4),
        lat: lat.toFixed(4),
        zoom: map.getZoom().toFixed(2),
      })
    })

    // Is the user on the map?
    this.locateUser()
  }

  render() {
    const { lng, lat, zoom } = this.state

    return (
      <div className='App'>
        <div
          ref={(el) => (this.mapContainer = el)}
          className='map'
          data-tap-disabled
          style={{ border: 'none' }}
        />

        {this.state.uebersichtBrandstaettenVisible && (
          <div className='layer-legend'>
            <div className='item'>
              <div className='indicator black' />
              abgebrannte Häuser
            </div>
            <div className='item'>
              <div className='indicator grey' />
              verschonte Häuser
            </div>
          </div>
        )}

        <TopMenu
          toggleSideMenu={this.toggleSideMenu}
          toggleImageSearch={this.toggleImageSearch}
          kapitel={this.state.kapitel}
          displayChapter={this.displayChapter}
          objekte={this.state.objekte}
          virtuelleAnsichten={this.state.virtuelleAnsichten}
          categories={this.state.categories}
          getObjectsInCategory={this.getObjectsInCategory}
          getCategoryName={this.getCategoryName}
          getObjectWPInformationByName={this.getObjectWPInformationByName}
          displayObjectInfo={this.displayObjectInfo}
          getObjectFeaturesByName={this.getObjectFeaturesByName}
          getObjectCoordinates={this.getObjectCoordinates}
          showOnMap={this.showOnMap}
          flyToLocation={this.flyToLocation}
          openPanviewer={this.openPanviewer}
          openVideoScroll={this.openVideoScroll}
          openLightbox={this.openLightbox}
          displayPage={this.displayPage}
          pages={this.state.pages}
          toggleAnsichten={this.toggleAnsichten}
          ansichtenVisible={this.state.ansichtenVisible}
          toggleGebaeude={this.toggleGebaeude}
          gebaeudeVisible={this.state.gebaeudeVisible}
          toggleBrandstaetten={this.toggleBrandstaetten}
          brandstaettenVisible={this.state.brandstaettenVisible}
          toggleGrafiken={this.toggleGrafiken}
          grafikenVisible={this.state.grafikenVisible}
          toggleRendering={this.toggleRendering}
          renderingVisible={this.state.renderingVisible}
          setRenderingOpacity={this.setRenderingOpacity}
          toggle3dModel={this.toggle3dModel}
          model3dVisible={this.state.model3dVisible}
          toggleCarree={this.toggleCarree}
          carreeVisible={this.state.carreeVisible}
          toggleUebersichtBrandstaetten={this.toggleUebersichtBrandstaetten}
          uebersichtBrandstaettenVisible={
            this.state.uebersichtBrandstaettenVisible
          }
          moveCustomMarker={this.moveCustomMarker}
          streetMarker={streetMarker}
          lng={this.state.lng}
          lat={this.state.lat}
          zoom={this.state.zoom}
          isDeveloperMode={this.state.isDeveloperMode}
        />

        <InfoDock
          isDeveloperMode={this.state.isDeveloperMode}
          activeObject={this.state.activeObject}
          openLightbox={this.openLightbox}
          dockIsVisible={this.state.dockIsVisible}
          openInfoDock={this.openInfoDock}
          closeInfoDock={this.closeInfoDock}
          menuIsVisible={this.state.menuIsVisible}
          showOnMap={this.showOnMap}
          locateObject={this.locateObject}
          getObjectWPInformationByName={this.getObjectWPInformationByName}
          getObjectFeaturesByName={this.getObjectFeaturesByName}
          getObjectCoordinates={this.getObjectCoordinates}
          displayObjectInfo={this.displayObjectInfo}
          flyToLocation={this.flyToLocation}
          toggleCarree={this.toggleCarree}
          toggleUebersichtBrandstaetten={this.toggleUebersichtBrandstaetten}
        />

        {(this.state.isMobile || this.state.isTablet) && (
          <SideMenu
            menuIsVisible={this.state.menuIsVisible}
            toggleSideMenu={this.toggleSideMenu}
            toggleImageSearch={this.toggleImageSearch}
            kapitel={this.state.kapitel}
            displayChapter={this.displayChapter}
            objekte={this.state.objekte}
            virtuelleAnsichten={this.state.virtuelleAnsichten}
            categories={this.state.categories}
            getObjectsInCategory={this.getObjectsInCategory}
            getCategoryName={this.getCategoryName}
            getObjectWPInformationByName={this.getObjectWPInformationByName}
            displayObjectInfo={this.displayObjectInfo}
            getObjectFeaturesByName={this.getObjectFeaturesByName}
            getObjectCoordinates={this.getObjectCoordinates}
            showOnMap={this.showOnMap}
            flyToLocation={this.flyToLocation}
            openPanviewer={this.openPanviewer}
            openVideoScroll={this.openVideoScroll}
            openLightbox={this.openLightbox}
            displayPage={this.displayPage}
            pages={this.state.pages}
            toggleAnsichten={this.toggleAnsichten}
            ansichtenVisible={this.state.ansichtenVisible}
            toggleGebaeude={this.toggleGebaeude}
            gebaeudeVisible={this.state.gebaeudeVisible}
            toggleBrandstaetten={this.toggleBrandstaetten}
            brandstaettenVisible={this.state.brandstaettenVisible}
            toggleGrafiken={this.toggleGrafiken}
            grafikenVisible={this.state.grafikenVisible}
            toggleRendering={this.toggleRendering}
            renderingVisible={this.state.renderingVisible}
            setRenderingOpacity={this.setRenderingOpacity}
            toggle3dModel={this.toggle3dModel}
            model3dVisible={this.state.model3dVisible}
            toggleCarree={this.toggleCarree}
            carreeVisible={this.state.carreeVisible}
            toggleUebersichtBrandstaetten={this.toggleUebersichtBrandstaetten}
            uebersichtBrandstaettenVisible={
              this.state.uebersichtBrandstaettenVisible
            }
            moveCustomMarker={this.moveCustomMarker}
            streetMarker={streetMarker}
            lng={this.state.lng}
            lat={this.state.lat}
            zoom={this.state.zoom}
            isDeveloperMode={this.state.isDeveloperMode}
          />
        )}

        <ImageSearch
          isDeveloperMode={this.state.isDeveloperMode}
          imageSearchIsVisible={this.state.imageSearchIsVisible}
          toggleImageSearch={this.toggleImageSearch}
          getObjectWPInformationByName={this.getObjectWPInformationByName}
          getObjectFeaturesByName={this.getObjectFeaturesByName}
          getObjectCoordinates={this.getObjectCoordinates}
          displayObjectInfo={this.displayObjectInfo}
          showOnMap={this.showOnMap}
          flyToLocation={this.flyToLocation}
          toggleSideMenu={this.toggleSideMenu}
          openLightbox={this.openLightbox}
        />
        <PhotoSwipe
          isDeveloperMode={this.state.isDeveloperMode}
          isOpen={this.state.lightboxOpen}
          items={this.state.lightboxItems}
          options={lightboxOptions}
          onClose={() => this.closeLightbox()}
        />
        {this.state.panviewerOpen && (
          <PanViewer
            isDeveloperMode={this.state.isDeveloperMode}
            image1860={this.state.image1860}
            image2011={this.state.image2011}
            isOpen={this.state.panviewerOpen}
            closePanviewer={this.closePanviewer}
          />
        )}
        {this.state.videoScrollIsOpen && (
          <VideoScroller
            closeVideoScroll={this.closeVideoScroll}
            isDeveloperMode={this.state.isDeveloperMode}
          />
        )}
        {!this.state.featuresLoaded && (
          <div id='initial-loader'>
            <div className='ui segment'>
              <div className='ui active dimmer'>
                <img
                  className='logo'
                  src={require('./images/logo.svg')}
                  alt='logo'
                />
                <div className='ui text loader'>Kartendaten werden geladen</div>
              </div>
              <p></p>
            </div>
          </div>
        )}
      </div>
    )
  }
}

export default App
