import React from 'react'
import { Dropdown, Icon, Input, Menu, Form, Checkbox } from 'semantic-ui-react'
import renderHTML from 'react-render-html'
import Nouislider from 'nouislider-react'
import 'nouislider/distribute/nouislider.css'
import { SemanticSearch } from '../semanticsearch'
import './styles.scss'

let kapitelStructure = []
let thematischeStructure = []
let alphabetischeStructure = []
let dropDownItemExecution = false

export class TopMenu extends React.Component<Props, State> {
  constructor(props) {
    super(props)
    this.state = {
      menuContentLoaded: false,
      isSideMenu: false,
      isMobile: window.innerWidth < 800,
      openDropdown: '',
    }
    this.handleChapterClick = this.handleChapterClick.bind(this)
    this.handleThematischClick = this.handleThematischClick.bind(this)
    this.handleMenuClick = this.handleMenuClick.bind(this)
    this.handleAlphabetischClick = this.handleAlphabetischClick.bind(this)
    this.handlePageClick = this.handlePageClick.bind(this)
    this.handleDropdownClick = this.handleDropdownClick.bind(this)
  }

  createKapitelMenuItems() {
    console.log('createKapitelMenuItems')
    console.log('this.props.kapitel', this.props.kapitel)
    kapitelStructure = this.props.kapitel.map((currKapitel) => ({
      key: 'chapter-' + currKapitel.id,
      text: renderHTML(currKapitel.title.rendered),
      value: currKapitel.id,
      onClick: this.handleChapterClick,
    }))
    console.log('kapitelStructure:', kapitelStructure)
  }

  createThematischeMenuItems() {
    console.log('createThematischeMenuItems')
    thematischeStructure = []

    // get all Categories used by objects
    let objektCategories = []
    let menuObjekte = this.props.virtuelleAnsichten.concat(this.props.objekte)
    objektCategories = [
      ...new Set(menuObjekte.map((currObjekt) => currObjekt.categories)),
    ]
    let usedCategories = [].concat.apply([], objektCategories)
    usedCategories = [...new Set(usedCategories)]
    console.log('######## usedCategories: ', usedCategories)

    // build menu structure
    let customOrderedCategories = this.props.categories
    customOrderedCategories.map(function (currentCat, index) {
      // Move Stadtmodell, Virtuelle Ansichten to first position
      if (currentCat.id === 52 || currentCat.id === 53) {
        // console.log('move:', currentCat.id)
        customOrderedCategories.splice(index, 1)
        customOrderedCategories.unshift(currentCat)
      }
    })
    console.log('customOrderedCategories:', customOrderedCategories)

    for (let i = 0; i < customOrderedCategories.length; i++) {
      let currentCat = this.props.categories.find(
        (x) => x.id === customOrderedCategories[i].id
      )
      // let currentCat = this.props.categories[i]
      console.log('currentCat:', currentCat)
      thematischeStructure.push({
        key: 'cat-' + currentCat.id,
        text: '<span className="category">' + currentCat.name + '</span>',
        value: currentCat.id,
        onClick: this.doNothing,
      })

      if (usedCategories.includes(currentCat.id)) {
        let catObjekte = this.props.getObjectsInCategory(currentCat.id)

        catObjekte.sort(function (a, b) {
          if (a.title.rendered < b.title.rendered) {
            return -1
          }
          if (a.title.rendered > b.title.rendered) {
            return 1
          }
          return 0
        })

        for (let y = 0; y < catObjekte.length; y++) {
          let currObjekt = catObjekte[y]
          thematischeStructure.push({
            key: 'cat-' + currentCat.id + 'obj-' + currObjekt.id,
            text:
              '<span className="sub-category">' +
              renderHTML(currObjekt.title.rendered) +
              '</span>',
            value: currObjekt.id,
            onClick: this.handleThematischClick,
          })
        }
      }
    }
    console.log('thematischeStructure:', thematischeStructure)
  }

  createAlphabetischeMenuItems() {
    console.log('createAlphabetischeMenuItems')

    /*
    let objekteAndVirtuelle = this.props.objekte.concat(
      this.props.virtuelleAnsichten
    )
    */
    alphabetischeStructure = this.props.objekte.map((currObjekt) => ({
      key: 'obj-' + currObjekt.id,
      text: renderHTML(currObjekt.title.rendered),
      value: currObjekt.id,
      onClick: this.handleAlphabetischClick,
    }))
    alphabetischeStructure.sort(function (a, b) {
      if (a.text < b.text) {
        return -1
      }
      if (a.text > b.text) {
        return 1
      }
      return 0
    })
    console.log('alphabetischeStructure:', alphabetischeStructure)
  }

  handleDropdownClick(e, { name }) {
    console.log('set open:', name)
    console.log('dropDownItemExecution:', this.dropDownItemExecution)
    if (this.state.openDropdown === name && dropDownItemExecution === false) {
      // close all dropdowns
      this.setState({ openDropdown: '' })
    } else {
      // open clicked dropdown
      this.setState({ openDropdown: name })
    }
  }

  keepParentDropdownOpen() {
    // QUITE HACKY TIMEOUT SOLUTION
    dropDownItemExecution = true
    // allow closing of parent dropdown after displaying the chapter
    setTimeout(() => {
      dropDownItemExecution = false
    }, 100)
  }

  handleChapterClick(e, { text, value }) {
    // console.log('text: ', text)
    console.log('open chapter: ', value)
    let chapterToLoad = this.props.kapitel.find((x) => x.id === value)
    this.props.displayChapter(chapterToLoad)
    // this.setState({ activeItem: text })
    this.keepParentDropdownOpen()
  }

  handleThematischClick(e, { text, value }) {
    console.log('text: ', text)
    console.log('open objekt: ', value)

    let cleanText = text.props.children[0]
    console.log('cleanText:', cleanText)
    let currentFeatures = this.props.getObjectFeaturesByName(cleanText)
    let currentTyp = currentFeatures[0].properties.typ
    console.log('currentTyp:', currentTyp)

    if (currentTyp === 'ansicht') {
      let currentObjekt = this.props.getObjectWPInformationByName(
        cleanText,
        'ansicht'
      )
      console.log('currentObjekt: ', currentObjekt)
      if (currentObjekt[0] !== undefined) {
        if (currentObjekt[0].acf !== undefined) {
          if (currentObjekt[0].acf.vergleich_bilder) {
            //comparison pano
            this.props.openPanviewer(currentObjekt)
          } else if (currentObjekt[0].acf.rendering_bild) {
            //rendering
            this.props.openLightbox(
              currentObjekt[0].acf.rendering_bild.url,
              currentObjekt[0].acf.rendering_bild.description,
              currentObjekt[0].title.rendered
            )
          } else {
            // ↑ SET A CONDITION ABOVE: WP SEQUENCE NAME or something...
            // rundumsicht video scroll
            this.props.openVideoScroll()
          }
        }
      }
    } else {
      this.props.showOnMap(cleanText, currentTyp)
      let currentCoordinates = this.props.getObjectCoordinates(currentFeatures)
      this.props.flyToLocation(
        currentCoordinates,
        this.state.isMobile ? 17 : 17.75
      )
    }
    //this.setState({ activeItem: text })
    this.keepParentDropdownOpen()
  }

  handleAlphabetischClick(e, { text, value }) {
    console.log('text: ', text)
    console.log('open objekt: ', value)

    let currentFeatures = this.props.getObjectFeaturesByName(text)
    let currentTyp = currentFeatures[0].properties.typ
    console.log('currentTyp:', currentTyp)

    if (currentTyp === 'ansicht') {
      let currentObjekt = this.props.getObjectWPInformationByName(
        text,
        'ansicht'
      )
      console.log('currentObjekt: ', currentObjekt)
      if (currentObjekt[0] !== undefined) {
        if (currentObjekt[0].acf !== undefined) {
          if (currentObjekt[0].acf.vergleich_bilder) {
            //comparison pano
            this.props.openPanviewer(currentObjekt)
          } else if (currentObjekt[0].acf.rendering_bild) {
            //rendering
            this.props.openLightbox(
              currentObjekt[0].acf.rendering_bild.url,
              currentObjekt[0].acf.rendering_bild.description,
              currentObjekt[0].title.rendered
            )
          } else {
            // ↑ SET A CONDITION ABOVE: WP SEQUENCE NAME or something...
            // rundumsicht video scroll
            this.props.openVideoScroll()
          }
        }
      }
    } else {
      this.props.showOnMap(text, currentTyp)
      let currentCoordinates = this.props.getObjectCoordinates(currentFeatures)
      this.props.flyToLocation(
        currentCoordinates,
        this.state.isMobile ? 17 : 17.75
      )
    }
    //this.setState({ activeItem: text })
    this.keepParentDropdownOpen()
  }

  handlePageClick(e, { text, value }) {
    //console.log('text: ', text)
    //console.log('open page: ', value)

    let pageToLoad = this.props.pages.find((x) => x.id === value)
    this.props.displayPage(pageToLoad)
    //this.setState({ activeItem: text })
    this.keepParentDropdownOpen()
  }

  handleMenuClick(e, { name }) {
    console.log(name)
    switch (name) {
      case 'toggle-sidemenu':
        this.props.toggleSideMenu()
        break
      case 'toggle-bildersuche':
        this.props.toggleImageSearch()
        break
      default:
      //nothing
    }
  }

  componentDidUpdate() {
    if (this.props.objekte.length > 0) {
      if (this.props.categories.length > 0) {
        if (!this.state.menuContentLoaded) {
          this.setState({ menuContentLoaded: true })
          this.createKapitelMenuItems()
          this.createThematischeMenuItems()
          this.createAlphabetischeMenuItems()
        }
      }
    }
  }

  componentDidMount() {}

  render() {
    // const { activeItem } = this.state
    return (
      <div>
        {
          /* LAYER TOGGLES INSIDE SIDEMENU, MOBILE ONLY */
          this.props.isSideMenu && (
            <div className='layers-menu'>
              {this.props.isDeveloperMode && (
                <Menu vertical className=''>
                  <Menu.Item>
                    {`Long: ${this.props.lng} | Lat: ${this.props.lat} | Zoom: ${this.props.zoom}`}
                  </Menu.Item>
                </Menu>
              )}
              <Form>
                <Form.Field>
                  <Checkbox
                    toggle
                    label='Modell 1860'
                    onClick={this.props.toggleRendering}
                    checked={this.props.renderingVisible}
                  />
                </Form.Field>
                {this.props.renderingVisible && (
                  <div>
                    <div className='slider-label'>Transparenz</div>
                    <div className='slider-wrapper'>
                      <Nouislider
                        range={{ min: 0, max: 100 }}
                        start={[100]}
                        connect
                        onSlide={(value) =>
                          this.props.setRenderingOpacity(Math.round(value))
                        }
                        // onSlide={value => this.setRenderingOpacity(Math.round(value))}
                        // onEnd={value => this.setRenderingOpacity(Math.round(value))}
                      />
                    </div>
                  </div>
                )}
                <Form.Field>
                  <Checkbox
                    toggle
                    label='Virtuelle Ansichten'
                    onClick={this.props.toggleAnsichten}
                    checked={this.props.ansichtenVisible}
                  />
                </Form.Field>
                <Form.Field>
                  <Checkbox
                    toggle
                    label='Gebäude & Orte'
                    onClick={this.props.toggleGebaeude}
                    checked={this.props.gebaeudeVisible}
                  />
                </Form.Field>
                <Form.Field>
                  <Checkbox
                    toggle
                    label='Fotos der Brandstätte'
                    onClick={this.props.toggleBrandstaetten}
                    checked={this.props.brandstaettenVisible}
                  />
                </Form.Field>
                <Form.Field>
                  <Checkbox
                    toggle
                    label='Gemälde & Grafiken'
                    onClick={this.props.toggleGrafiken}
                    checked={this.props.grafikenVisible}
                  />
                </Form.Field>
                {this.props.isDeveloperMode && (
                  <Form.Field>
                    <Checkbox
                      toggle
                      label='3D Modell'
                      onClick={this.props.toggle3dModel}
                      checked={this.props.model3dVisible}
                    />
                  </Form.Field>
                )}
                <Form.Field>
                  <Checkbox
                    toggle
                    label='Geplantes Carrée'
                    onClick={this.props.toggleCarree}
                    checked={this.props.carreeVisible}
                  />
                </Form.Field>
                <Form.Field>
                  <Checkbox
                    toggle
                    label='Übersicht Brandstätte'
                    onClick={this.props.toggleUebersichtBrandstaetten}
                    checked={this.props.uebersichtBrandstaettenVisible}
                  />
                </Form.Field>
              </Form>
            </div>
          )
        }

        {/* TOP MENU ON DESKTOP (HORIZONTAL) || MOBILE INSIDE SIDEBAR (VERTICAL) || MOBILE ON TOP (SEARCH AND BURGER ONLY, HORIZONTAL)  */}
        <div className='top-menu'>
          <Menu className={this.props.isSideMenu ? 'vertical' : 'horizontal'}>
            {(!this.state.isMobile || this.props.isSideMenu) && (
              <Dropdown
                item
                name='kapitel'
                open={this.state.openDropdown === 'kapitel'}
                onClick={this.handleDropdownClick}
                trigger={
                  <span>
                    <Icon name='bookmark' />
                    Kapitel
                  </span>
                }
                options={kapitelStructure.map((subitem) => ({
                  key: subitem['key'],
                  text: subitem['text'],
                  value: subitem['value'],
                  onClick: subitem['onClick'],
                }))}
              />
            )}

            {(!this.state.isMobile || this.props.isSideMenu) && (
              <Dropdown
                item
                name='thematische-suche'
                open={this.state.openDropdown === 'thematische-suche'}
                onClick={this.handleDropdownClick}
                trigger={
                  <span>
                    <Icon name='sitemap' />
                    Thematische Suche
                  </span>
                }
                scrolling
                options={thematischeStructure.map((subitem) => ({
                  key: subitem['key'],
                  text: renderHTML(subitem['text']),
                  value: subitem['value'],
                  onClick: subitem['onClick'],
                }))}
                onChange={this.doNothing}
              ></Dropdown>
            )}

            {(!this.state.isMobile || this.props.isSideMenu) && (
              <Dropdown
                item
                name='alphabetische-suche'
                open={this.state.openDropdown === 'alphabetische-suche'}
                onClick={this.handleDropdownClick}
                trigger={
                  <span>
                    <Icon name='sort alphabet down' />
                    Alphabetische Suche
                  </span>
                }
                scrolling
                options={alphabetischeStructure.map((subitem) => ({
                  key: subitem['key'],
                  text: subitem['text'],
                  value: subitem['value'],
                  onClick: subitem['onClick'],
                }))}
                onChange={this.doNothing}
              ></Dropdown>
            )}

            {(!this.state.isMobile || this.props.isSideMenu) && (
              <Menu.Item
                name='toggle-bildersuche'
                onClick={this.handleMenuClick}
              >
                <Icon name='image' />
                Bildersuche
              </Menu.Item>
            )}

            {(!this.state.isMobile || this.props.isSideMenu) && (
              <Dropdown
                item
                name='impressum'
                open={this.state.openDropdown === 'impressum'}
                onClick={this.handleDropdownClick}
                trigger={
                  <span>
                    <Icon name='info circle' />
                    Impressum
                  </span>
                }
              >
                <Dropdown.Menu>
                  <Dropdown.Item
                    key='page-382'
                    text='Das Projekt'
                    value={382}
                    onClick={this.handlePageClick}
                  />
                  <Dropdown.Item
                    key='page-392'
                    text='Die Entwickler'
                    value={392}
                    onClick={this.handlePageClick}
                  />
                  <Dropdown.Item
                    key='page-410'
                    text='Bibliografie'
                    value={410}
                    onClick={this.handlePageClick}
                  />
                  <Dropdown.Item
                    key='page-424'
                    text='Sponsoren'
                    value={424}
                    onClick={this.handlePageClick}
                  />
                  <Dropdown.Item
                    key='page-384'
                    text='Kontakt'
                    value={384}
                    onClick={this.handlePageClick}
                  />
                </Dropdown.Menu>
              </Dropdown>
            )}

            {!this.props.isSideMenu && (
              <Menu.Item style={{ padding: '0' }}>
                <div className='ui search'>
                  <SemanticSearch
                    flyToLocation={this.props.flyToLocation}
                    getObjectWPInformationByName={
                      this.props.getObjectWPInformationByName
                    }
                    getObjectFeaturesByName={this.props.getObjectFeaturesByName}
                    displayObjectInfo={this.props.displayObjectInfo}
                    showOnMap={this.props.showOnMap}
                    getObjectCoordinates={this.props.getObjectCoordinates}
                    moveCustomMarker={this.props.moveCustomMarker}
                    streetMarker={this.props.streetMarker}
                  />
                </div>
              </Menu.Item>
            )}

            {this.state.isMobile && !this.props.isSideMenu && (
              <Menu.Item
                name='toggle-sidemenu'
                onClick={this.handleMenuClick}
                /* style={{ position: 'absolute', right: '0', height: '100%' }} */
              >
                <Icon name='bars' />
              </Menu.Item>
            )}
          </Menu>
        </div>

        {
          /* LAYER TOGGLES: DESKTOP ONLY */
          !this.state.isMobile && (
            <div className='layers-menu'>
              <Form>
                <Form.Field>
                  <Checkbox
                    toggle
                    label='Modell 1860'
                    onClick={this.props.toggleRendering}
                    checked={this.props.renderingVisible}
                  />
                </Form.Field>
                {this.props.renderingVisible && (
                  <div>
                    <div className='slider-label'>Transparenz</div>
                    <div className='slider-wrapper'>
                      <Nouislider
                        range={{ min: 0, max: 100 }}
                        start={[100]}
                        connect
                        onSlide={(value) =>
                          this.props.setRenderingOpacity(Math.round(value))
                        }
                        // onSlide={value => this.setRenderingOpacity(Math.round(value))}
                        // onEnd={value => this.setRenderingOpacity(Math.round(value))}
                      />
                    </div>
                  </div>
                )}
                <Form.Field>
                  <Checkbox
                    toggle
                    label='Virtuelle Ansichten'
                    onClick={this.props.toggleAnsichten}
                    checked={this.props.ansichtenVisible}
                  />
                </Form.Field>
                <Form.Field>
                  <Checkbox
                    toggle
                    label='Gebäude & Orte'
                    onClick={this.props.toggleGebaeude}
                    checked={this.props.gebaeudeVisible}
                  />
                </Form.Field>
                <Form.Field>
                  <Checkbox
                    toggle
                    label='Fotos der Brandstätte'
                    onClick={this.props.toggleBrandstaetten}
                    checked={this.props.brandstaettenVisible}
                  />
                </Form.Field>
                <Form.Field>
                  <Checkbox
                    toggle
                    label='Gemälde & Grafiken'
                    onClick={this.props.toggleGrafiken}
                    checked={this.props.grafikenVisible}
                  />
                </Form.Field>
                {this.props.isDeveloperMode && (
                  <Form.Field>
                    <Checkbox
                      toggle
                      label='3D Modell'
                      onClick={this.props.toggle3dModel}
                      checked={this.props.model3dVisible}
                    />
                  </Form.Field>
                )}
                <Form.Field>
                  <Checkbox
                    toggle
                    label='Geplantes Carrée'
                    onClick={this.props.toggleCarree}
                    checked={this.props.carreeVisible}
                  />
                </Form.Field>
                <Form.Field>
                  <Checkbox
                    toggle
                    label='Übersicht Brandstätte'
                    onClick={this.props.toggleUebersichtBrandstaetten}
                    checked={this.props.uebersichtBrandstaettenVisible}
                  />
                </Form.Field>
              </Form>
              {this.props.isDeveloperMode && (
                <Menu vertical className='coordinate-info'>
                  <Menu.Item>
                    {`Long: ${this.props.lng} | Lat: ${this.props.lat} | Zoom: ${this.props.zoom}`}
                  </Menu.Item>
                </Menu>
              )}
            </div>
          )
        }
      </div>
    )
  }
}
