import React from 'react'
import renderHTML from 'react-render-html'
import { Search } from 'semantic-ui-react'
import './styles.scss'

const initialState = { isLoading: false, results: [], value: '' }

export class SemanticSearch extends React.Component<Props, State> {
  state = initialState
  constructor(props) {
    super(props)
    this.state = {
      objektSearchResults: [],
      adressSearchResults: [],
      isMobile: window.innerWidth < 600,
    }
  }

  handleResultSelect = (e, { result }) => {
    console.log(result)
    this.setState({ isLoading: false })

    switch (result.type) {
      case 'objekt':
        let targetObjekt = result.title.props.children[0].props.children[0]
        this.props.showOnMap(targetObjekt)
        break
      case 'strasse':
        let targetCoordinates = [result.lon, result.lat]
        let targetName = result.title.props.children[0].props.children[0].replace(
          ' <b>8750 Glarus</b>',
          ''
        )
        console.log('fly to:', targetName)
        console.log('targetCoordinates:', targetCoordinates)
        this.props.flyToLocation(
          targetCoordinates,
          this.state.isMobile ? 17 : 17.75
        )
        this.props.moveCustomMarker(
          this.props.streetMarker,
          [result.lon, result.lat],
          targetName
        )
        break
      default:
      //nothing
    }
    /*
    
    */

    this.setState({ value: '' })
  }

  handleSearchChange = (e, { value }) => {
    this.setState({ isLoading: true, value })

    // FETCH OBJEKTE
    if (e.target.value.length >= 1) {
      console.log('handleSearchChange: ', e.target.value)
      let searchString = e.target.value
      fetch(
        'https://altglarus.ch/backend/wp-json/wp/v2/objekt?search=' +
          searchString
      )
        .then((response) => {
          if (response.ok) {
            return response.json()
          } else {
            throw new Error("WP-Server response wasn't OK")
          }
        })
        .then((responseData) => {
          console.log('get matching object titles: ', responseData)
          let matchingTitles = responseData.filter((objekt) =>
            objekt.title.rendered
              .toLowerCase()
              .includes(searchString.toLowerCase())
          )
          console.log('handleSearchChange matchingTitles: ', matchingTitles)
          this.setState({ objektSearchResults: matchingTitles }, () => {
            fetch(
              'https://api3.geo.admin.ch/rest/services/api/SearchServer?searchText=' +
                searchString +
                '&bbox=722582.73,210184.13,725006.39,212546.58&type=locations' // search query uses a bounding box for glarus (roughly)
            )
              .then((response) => {
                if (response.ok) {
                  return response.json()
                } else {
                  throw new Error("GeoAdmin-Server response wasn't OK")
                }
              })
              .then((responseData) => {
                //console.log('responseData: ')
                //console.log(responseData.results)
                let filterString = '8750 Glarus'
                let matchingAdresses = responseData.results.filter(
                  (adresse) => adresse.attrs.label.indexOf(filterString) !== -1
                )
                //sort alphabteically
                // matchingAdresses.sort((a, b) => a.attrs.label - b.attrs.label)
                matchingAdresses.sort((a, b) =>
                  a.attrs.label > b.attrs.label ? 1 : -1
                )
                //remove duplicates
                matchingAdresses = matchingAdresses.filter(
                  (currAdress, index, self) =>
                    index ===
                    self.findIndex(
                      (t) => t.attrs.label === currAdress.attrs.label
                    )
                )
                console.log('matchingAdresses: ', matchingAdresses)
                this.setState(
                  {
                    adressSearchResults: matchingAdresses,
                  },
                  () => {
                    //create results object
                    // console.log(this.state.objektSearchResults)
                    // console.log(this.state.adressSearchResults)

                    let objektSearchResults = this.state.objektSearchResults.map(
                      (currObjekt) => ({
                        key: 'obj-' + currObjekt.id,
                        title: renderHTML(
                          '<i className="bullseye icon" /><span>' +
                            currObjekt.title.rendered +
                            '</span>'
                        ),
                        type: currObjekt.type,
                      })
                    )

                    let adressSearchResults = this.state.adressSearchResults.map(
                      (currAdress) => ({
                        key:
                          'str-' +
                          currAdress.attrs.label.replace(
                            ' <b>8750 Glarus</b>',
                            ''
                          ),
                        title: renderHTML(
                          '<i className="map pin icon" /><span>' +
                            currAdress.attrs.label.replace(
                              ' <b>8750 Glarus</b>',
                              ''
                            ) +
                            '</span>'
                        ),
                        lat: currAdress.attrs.lat,
                        lon: currAdress.attrs.lon,
                        type: 'strasse',
                      })
                    )

                    let searchOutput = {}
                    if (objektSearchResults.length) {
                      searchOutput['objekte'] = {
                        name: 'Objekte',
                        results: objektSearchResults,
                      }
                    }
                    if (adressSearchResults.length) {
                      searchOutput['strassen'] = {
                        name: 'Strassen',
                        results: adressSearchResults,
                      }
                    }

                    console.log('searchOutput:', searchOutput)
                    this.setState({ results: searchOutput })
                  }
                )
              })
          })
        })
    } else {
      this.setState({ objektSearchResults: [] })
      this.setState({ adressSearchResults: [] })
    }
  }

  render() {
    return (
      <div className='semantic-search'>
        <Search
          aligned={this.state.isMobile ? 'left' : 'right'}
          category
          loading={this.state.isLoading}
          onResultSelect={this.handleResultSelect}
          onSearchChange={this.handleSearchChange}
          results={this.state.results}
          value={this.state.value}
          placeholder='Objekt/Adresse suchen...'
          noResultsMessage='Keine Resultate gefunden'
        />
      </div>
    )
  }
}
