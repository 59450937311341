import React from 'react'
import { motion, motionValue } from 'framer-motion'
import { PanIndicator } from '../panindicator'
import './styles.scss'

const isMobile = window.innerWidth < 600

const x = motionValue(0) // MAD: how can I set the initial value (centered image) -> useMotionValue hook?

export class PanViewer extends React.Component<Props, State> {
  constructor(props) {
    super(props)
    this.state = {
      imgDimensions: {},
      wrapperWidth: -1,
      windowWidth: -1,
      totalDegrees: 120,
      xPos: 0,
      isRenderView: true,
      userInteracted: false,
    }
    this.wrapperRef = React.createRef()
    this.imgRef = React.createRef()

    x.onChange((value) =>
      this.setState(
        {
          xPos: value,
        },
        () =>
          console.log(
            'X: ' +
              value +
              ' | imageWidth: ' +
              this.state.imgDimensions.width +
              ' | wrapperWidth: ' +
              this.state.wrapperWidth
          ),
        this.setState({ userInteracted: true }),
        console.log('user interacted')
      )
    )

    this.onImgLoad = this.onImgLoad.bind(this)
    this.calculateConstraints = this.calculateConstraints.bind(this)
    this.handleResize = this.handleResize.bind(this)
    this.toggleView = this.toggleView.bind(this)
  }

  onImgLoad({ target: img }) {
    this.setState(
      {
        imgDimensions: { height: img.offsetHeight, width: img.offsetWidth },
      },
      () => console.log(this.state.imgDimensions)
    )
    this.setState(
      {
        wrapperWidth: this.wrapperRef.current.offsetWidth,
      },
      () => console.log(this.state.wrapperWidth)
    )
  }

  calculateConstraints() {
    console.log('calculateConstraints')
    const widthDiff = this.state.imgDimensions.width - this.state.wrapperWidth
    return -widthDiff
  }

  handleResize(e) {
    console.log('*** handleResize')
    this.setState(
      {
        wrapperWidth: this.wrapperRef.current.offsetWidth,
      },
      () => console.log('wrapperWidth', this.state.wrapperWidth)
    )

    this.setState(
      {
        imgDimensions: {
          height: this.imgRef.current.offsetHeight,
          width: this.imgRef.current.offsetWidth,
        },
      },
      () => console.log('imgDimensions', this.state.imgDimensions)
    )
  }

  handleMotion(value) {
    console.log(value)
  }

  toggleView() {
    this.setState({
      isRenderView: !this.state.isRenderView,
    })
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleResize)
    this.calculateConstraints()
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize)
  }

  render() {
    let renderImage = this.props.image1860
    let photoImage = this.props.image2011
    return (
      <div
        className='pan-viewer'
        style={{ visibility: this.props.isOpen ? 'visible' : 'hidden' }}
      >
        <div ref={this.wrapperRef} className='pan-wrapper'>
          <motion.img
            key={this.state.isRenderView ? renderImage : photoImage}
            src={this.state.isRenderView ? renderImage : photoImage}
            ref={this.imgRef}
            drag='x'
            style={{ x }}
            dragConstraints={{ left: this.calculateConstraints(), right: 0 }}
            onLoad={this.onImgLoad}
            onMouseDown={(e) => e.preventDefault()} // fix for firefoy (make image not draggable)
          />
          {/* this is just for preloading the 2nd image} */}
          <img
            src={photoImage}
            alt='preload-dummy'
            style={{ opacity: '0.00001' }}
          />
        </div>
        <div className='toggle-wrapper'>
          <div className='view-toggle'>
            <div className='mini ui buttons'>
              <button
                className={
                  this.state.isRenderView ? 'ui positive button' : 'ui button'
                }
                onClick={this.toggleView}
              >
                1860
              </button>
              <button
                className={
                  this.state.isRenderView ? 'ui button' : 'ui positive button'
                }
                onClick={this.toggleView}
              >
                2011
              </button>
            </div>
          </div>
        </div>
        <PanIndicator
          xpos={this.state.xPos}
          imageWidth={this.state.imgDimensions.width}
          wrapperWidth={this.state.wrapperWidth}
          totalDegrees={this.state.totalDegrees}
        />
        <div
          className={
            this.state.userInteracted ? 'drag-hint hidden' : 'drag-hint'
          }
        >
          <img src={require('../images/drag-hint.svg')} />
        </div>
        <div className='closer' onClick={() => this.props.closePanviewer()}>
          <i className='icon close large' />
        </div>
      </div>
    )
  }
}
