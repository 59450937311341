import React from 'react'
import { VideoScroll } from 'react-video-scroll'
import './styles.scss'

const playBackSpeed = 400 // lower is faster: 400 = 0.0025 video seconds per Pixel

export class VideoScroller extends React.Component<Props, State> {
  constructor(props) {
    super(props)
    this.state = {
      showScrollHint: false,
      userInteracted: false,
      videoReadyToPlayThrough: false,
      scrollDistance: 6953, // just a ruff value
    }
    this.isMobile = window.innerWidth < 600
    this.userScrolls = this.userScrolls.bind(this)
  }

  videoCanPlayThrough() {
    if (!this.state.videoReadyToPlayThrough) {
      this.setState({ videoReadyToPlayThrough: true })
    }
    // console.log('>>>> videoCanPlayThrough <<<<')
  }
  videoCanPlay() {
    if (!this.state.showScrollHint) {
      this.setState({ showScrollHint: true })
    }
    // console.log('>>>> videoCanPlay <<<<')
  }
  userScrolls() {
    if (!this.state.userInteracted) {
      this.setState({ userInteracted: true })
    }
  }

  componentDidMount() {
    window.addEventListener('scroll', this.userScrolls)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.userScrolls)
  }

  render() {
    return (
      <div
        className='video-scroller'
        style={{ height: this.state.scrollDistance + 'px' }}
      >
        {!this.state.videoReadyToPlayThrough && (
          <div className='ui active centered inline loader'>
            Modell wird geladen
          </div>
        )}

        <VideoScroll
          playbackRate={playBackSpeed} // 0.0025 video seconds per Pixel
          horizontalScroll={false}
          style={{
            left: this.isMobile ? '0' : '50%',
            zIndex: '9999',
          }}
        >
          <video
            autoPlay={this.isMobile ? true : false} // works for iOS, might be different in android other iOS versions. MAD: do testing!
            muted
            playsInline
            preload
            autobuffer
            ref={(video) => {
              if (video) {
                video.setAttribute('playsinline', '')
              }
            }}
            style={{ width: '100%' }}
            onLoadedMetadata={(e) => {
              // find other media events here: https://reactjs.org/blog/2015/09/10/react-v0.14-rc1.html
              // console.log('duration:', e.target.duration)
              // console.log('window.innerHeight:', window.innerHeight)

              // calculate the containers height (scrolling distance)
              let scrollSecondsRatio = 1 / playBackSpeed
              // console.log('scrollSecondsRatio:', scrollSecondsRatio)
              let distance =
                Math.round(e.target.duration / scrollSecondsRatio) +
                window.innerHeight
              // console.log('calculated scrollDistance:', distance)
              this.setState({ scrollDistance: distance })
            }}
            onCanPlay={() => this.videoCanPlay()}
            onCanPlayThrough={() => this.videoCanPlayThrough()}
            onTimeUpdate={(e) => {
              /*
              console.log('currentTime:', e.target.currentTime)
              console.log('window.scrollY:', window.scrollY)
              */
            }}
            onEnded={() => {
              // console.log('video end reached!')
            }}
          >
            {!this.isMobile && (
              <source
                src={require('./videos/rundlauf-vp9-k8-1024kbs.webm')}
                type='video/webm'
                poster={require('./videos/rundlauf.jpg')}
              />
            )}
            {/* MP4 for iOS */}
            {this.isMobile && (
              <source
                src={require('./videos/rundlauf.mp4')}
                type='video/mp4'
                poster={require('./videos/rundlauf.jpg')}
              />
            )}
          </video>
        </VideoScroll>
        <div
          className={
            this.state.userInteracted ? 'scroll-hint hidden' : 'scroll-hint'
          }
        >
          {this.isMobile && <img src={require('../images/scroll-hint.svg')} />}
          {!this.isMobile && <i className='icon chevron down large' />}
        </div>
        <div className='closer' onClick={() => this.props.closeVideoScroll()}>
          <i className='icon close large' />
        </div>
      </div>
    )
  }
}
