import React from 'react'
import './styles.scss'

// see: https://stackoverflow.com/questions/51569981/how-to-use-react-links-when-rendering-content-with-dangerouslysetinnerhtml/51570332#51570332

export class WpTextContent extends React.Component<Props, State> {
  constructor(props) {
    super(props)
  }

  contentClickHandler = (e) => {
    console.log('contentClickHandler')
    const targetLink = e.target.closest('a')
    if (!targetLink) {
      return
    } else {
      if (targetLink.rel) {
        e.preventDefault()
        let linkTarget = /[^/]*$/.exec(decodeURI(targetLink.href))[0]
        console.log('show: ' + targetLink.rel + ' ' + linkTarget)
        switch (targetLink.rel) {
          case 'brandverlauf':
            this.showBrandverlauf()
            break
          case 'displayObjectInfo':
            let currentObjekt = this.props.getObjectWPInformationByName(
              linkTarget
            )
            this.props.displayObjectInfo(currentObjekt)
            break
          case 'showOnMap':
            this.props.showOnMap(linkTarget, targetLink.type ? targetLink.type : '')
            break
          case 'locateObject':
            this.props.locateObject(linkTarget, targetLink.type ? targetLink.type : '')
            break
          default:
            console.log('?? DEFAULT LINK, WHAT SHOULD I DO WITH THIS ??')
            break
        }
      } else {
        // console.log('FOLLOW LINK')
      }
    }
  }

  showBrandverlauf() {
    this.props.flyToLocation([9.0681, 47.0377], 17)
  }

  render() {
    //console.log('WPTextContent: ', this.props.content)
    return (
      <div className='text-content'>
        <div
          onClick={this.contentClickHandler}
          dangerouslySetInnerHTML={{ __html: this.props.content }}
        />
      </div>
    )
  }
}
