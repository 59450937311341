import React from 'react'
import Dock from 'react-dock'
import { TopMenu } from '../topmenu'
import './styles.scss'

export class SideMenu extends React.Component<Props, State> {
  constructor(props) {
    super(props)
    this.state = {
      isMobile: window.innerWidth < 600,
      isTablet: window.innerWidth < 800,
    }
  }

  componentDidMount() {}

  render() {
    return (
      <div
        className={
          this.isMobile && this.props.menuIsVisible
            ? 'side-menu mobile'
            : 'side-menu'
        }
      >
        <Dock
          position='right'
          size={this.state.isMobile ? 0.85 : 0.4}
          dimMode='none'
          isVisible={this.props.menuIsVisible}
        >
          <TopMenu
            isSideMenu // <-- THIS IS IMPORTANT
            toggleSideMenu={this.props.toggleSideMenu}
            toggleImageSearch={this.props.toggleImageSearch}
            kapitel={this.props.kapitel}
            displayChapter={this.props.displayChapter}
            objekte={this.props.objekte}
            virtuelleAnsichten={this.props.virtuelleAnsichten}
            categories={this.props.categories}
            getObjectsInCategory={this.props.getObjectsInCategory}
            getCategoryName={this.props.getCategoryName}
            getObjectWPInformationByName={
              this.props.getObjectWPInformationByName
            }
            displayObjectInfo={this.props.displayObjectInfo}
            getObjectFeaturesByName={this.props.getObjectFeaturesByName}
            getObjectCoordinates={this.props.getObjectCoordinates}
            showOnMap={this.props.showOnMap}
            flyToLocation={this.props.flyToLocation}
            openPanviewer={this.props.openPanviewer}
            openVideoScroll={this.props.openVideoScroll}
            openLightbox={this.props.openLightbox}
            displayPage={this.props.displayPage}
            pages={this.props.pages}
            toggleAnsichten={this.props.toggleAnsichten}
            ansichtenVisible={this.props.ansichtenVisible}
            toggleGebaeude={this.props.toggleGebaeude}
            gebaeudeVisible={this.props.gebaeudeVisible}
            toggleBrandstaetten={this.props.toggleBrandstaetten}
            brandstaettenVisible={this.props.brandstaettenVisible}
            toggleGrafiken={this.props.toggleGrafiken}
            grafikenVisible={this.props.grafikenVisible}
            toggleRendering={this.props.toggleRendering}
            renderingVisible={this.props.renderingVisible}
            setRenderingOpacity={this.props.setRenderingOpacity}
            toggle3dModel={this.props.toggle3dModel}
            model3dVisible={this.props.model3dVisible}
            toggleCarree={this.props.toggleCarree}
            carreeVisible={this.props.carreeVisible}
            toggleUebersichtBrandstaetten={
              this.props.toggleUebersichtBrandstaetten
            }
            uebersichtBrandstaettenVisible={
              this.props.uebersichtBrandstaettenVisible
            }
            moveCustomMarker={this.props.moveCustomMarker}
            streetMarker={this.props.streetMarker}
            lng={this.props.lng}
            lat={this.props.lat}
            zoom={this.props.zoom}
            isDeveloperMode={this.props.isDeveloperMode}
          />
        </Dock>
      </div>
    )
  }
}
