import React from 'react'

export class LightboxLink extends React.Component<Props, State> {
  constructor() {
    super()
    this.handleShowClick = this.handleShowClick.bind(this)
  }

  handleShowClick(tester) {
    console.log('^^^ LIGHTBOXLINK: SHOW ON MAP', tester, ' ^^^')
    this.props.showOnMap(this.props.objektTitle)
  }

  render() {
    return (
      <a
        className='whatever'
        href='#showonmap'
        onClick={this.handleShowClick(this.props.objektTitle)}
      >
        ⌾ {this.props.objektTitle} auf der Karte zeigen
      </a>
    )
  }
}
