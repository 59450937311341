import React from 'react'
import Img from 'react-cool-img'
import loadingImage from '../images/loading.gif'
import './styles.scss'

export class HeadImage extends React.Component<Props, State> {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    return (
      <div>
        <Img
          className='head-image'
          placeholder={loadingImage}
          src={
            this.props.imgArray
              ? this.props.imgArray[this.props.headImageIndex].objekt_bild.sizes
                  .medium_large
              : this.props.imgSource
          }
          onClick={() =>
            this.props.openLightbox(
              this.props.imgArray
                ? this.props.imgArray[this.props.headImageIndex].objekt_bild.url
                : this.props.lightboxURL,
              this.props.imgArray
                ? this.props.imgArray[
                    this.props.headImageIndex
                  ].objekt_bild.description.replace(/(<([^>]+)>)/gi, '')
                : this.props.lightboxDescription.replace(
                    /(<([^>]+)>)/gi,
                    ''
                  ) /* all HTML Tags stripped */
            )
          }
          style={{ width: '100%', height: 'auto' }}
        />
      </div>
    )
  }
}
