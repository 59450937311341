import React from 'react'
import Dock from 'react-dock'
import { ImageResults } from '../imageresults'
import './styles.scss'

const dataRoute =
  'https://altglarus.ch/backend/wp-json/wp/v2/media/?per_page=100'

export class ImageSearch extends React.Component<Props, State> {
  constructor(props) {
    super(props)
    this.state = {
      allWpMedia: [],
      allImages: [],
    }
    this.isMobile = window.innerWidth < 600
  }
  getAllImages() {
    console.log('getAllImages')
    /* GET WP MEDIA */
    fetch(dataRoute).then(
      function (response) {
        const contentType = response.headers.get('content-type')
        const totalCount = response.headers.get('x-wp-total')
        const pages = response.headers.get('x-wp-totalpages')

        if (contentType && contentType.includes('application/json')) {
          this.loadMore(totalCount, pages)
          return response.json()
        }
        throw new TypeError('Oops, the format is not JSON.')
      }.bind(this)
    )
  }

  // Load more WP media, 100 items per page
  loadMore(totalCount, pages) {
    let allPosts = []
    // Loop all pages, which was counted from the first REST API fetch.
    for (let i = 1; i <= pages; i++) {
      fetch(dataRoute + '&page=' + i)
        .then((response) => {
          if (response.ok) {
            return response.json()
          } else {
            throw new Error("WP-Server response wasn't OK")
          }
        })
        .then((responseData) => {
          allPosts = allPosts.concat(responseData)
          console.log('**** allPosts ****', allPosts)

          let filteredImages
          this.setState({ allWpMedia: allPosts }, () =>
            console.log('######## allWpMedia: ', this.state.allWpMedia)
          )
          filteredImages = allPosts.filter(
            (currMedia) => currMedia.media_type === 'image' // && currMedia.acf !== false
          )
          this.setState({ allImages: filteredImages }, () =>
            console.log('######## allImages: ', this.state.allImages)
          )
        })
    }
  }

  componentDidUpdate() {}

  componentDidMount() {
    this.getAllImages()
  }

  render() {
    return (
      <div
        className={
          this.isMobile && this.props.menuIsVisible
            ? 'image-search mobile'
            : 'image-search'
        }
      >
        <Dock
          position={'right'}
          size={0.95}
          dimMode='none'
          isVisible={this.props.imageSearchIsVisible}
          className='dock'
        >
          <div className='dock'>
            <i
              className='icon close'
              onClick={() => this.props.toggleImageSearch()}
            />
            <div className='search-content'>
              <ImageResults
                allImages={this.state.allImages}
                getObjectWPInformationByName={
                  this.props.getObjectWPInformationByName
                }
                getObjectFeaturesByName={this.props.getObjectFeaturesByName}
                getObjectCoordinates={this.props.getObjectCoordinates}
                displayObjectInfo={this.props.displayObjectInfo}
                showOnMap={this.props.showOnMap}
                flyToLocation={this.props.flyToLocation}
                toggleImageSearch={this.props.toggleImageSearch}
                toggleSideMenu={this.props.toggleSideMenu}
                openLightbox={this.props.openLightbox}
              />
            </div>
          </div>
        </Dock>
      </div>
    )
  }
}
